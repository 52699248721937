import { combineReducers } from 'redux'
import user from './user.reducer'
import site from './site.reducer'
import confirmDialog from './confirmDialog.reducer'

export default combineReducers({
    user,
    site,
    confirmDialog
})
