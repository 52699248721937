//import logo from './logo.svg';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../image/logo_main.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { forgetPassword } from '../../api/index.js'
import { hideLoading, showLoading } from '../../helper/Helper.js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';

function ForgotPassword() {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();  

    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!regEmail.test(formData.email)){
      dispatch(dialogConfirmSetData({
          title: "Error",
          message: "Invalid Email.",
          closeButton: "",
          confirmButton: "OK",
          eventConfirmButton: () => null,
          eventCloseButton: () => null,
          showCloseButton: false
      }));
      // ** ทำให้ dialog โชว์ขึ้นมา **
      dispatch(dialogConfirmSetShowModal(true));
    
    } else {
      showLoading();
      forgetPassword(formData.email).then(handleForgotPassword)
    }
  }

  const handleForgotPassword = async (response) => {
    
    hideLoading();

    var title = "";
    var message = "";
    var button = "OK";
    if(response.data["message"]["title"] != null){
      title = response.data["message"]["title"];
    }

    if(response.data["message"]["message"] != null){
      message = response.data["message"]["message"];
    }

    if(response.data["message"]["button"] != null){
      button = response.data["message"]["button"];
    }

    dispatch(dialogConfirmSetData({
        title: title,
        message: message,
        closeButton: null,
        confirmButton: button,
        eventConfirmButton: () => {
          if(response.data["error"] == false){
            navigate('/', {replace: true});
          }
        },
        eventCloseButton: null,
        showCloseButton: false
    }));

      // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));

  }


  return (

    <div className="boyd-div">
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <Container>
        <Row>
          <Col>
            <div className="container-fluid">
              <div className="container" >
                <div className="row">
                  <div className='col-3'></div>
                  <div className='col-6  login-content'>
                    <center className='mt-5 '>
                      <img src={logo} className="App-logo" alt="logo" width="60%" />
                 
                      <h3> <b style={{ fontSize: 30 }}>Trouble with logging in?</b></h3>
                      <span style={{ fontSize: 18 }} className="textEnter" >
                        Enter your username or email address and we'll send you a  <br></br>
                        link to get back into your account.
                      </span>
                    </center>
                    <br></br>
                    <form onSubmit={handleSubmit}>

                      <div style={{textAlign: 'center'}}>

                        <input type="email" placeholder="Email" name="email" value={formData.email || ''} onChange={handleChange} className='form-control' required style={{width: '100%', paddingLeft: '40px', paddingRight: '40px'}}/>
                      </div>

                      <div className='' style={{width: '100%'}}>
                        <a href='/' className="button-back" style={{ margin: '0px', strokeWidth: '2', backgroundColor: 'transparent', color: 'var(--color-main-theme)' ,stroke: 'black', border: '2px solid var(--color-main-theme)', marginTop: '15px', width: '48%', fontSize: '19px', textDecoration: 'none'}}> <b> BACK </b></a>
                        <button className="button-reset" style={{ width: '50%', float: 'right', marginTop: '15px' }} type="submit"> <b> RESET PASSWORD </b></button>
                      </div>
                      <br></br>

                    </form>

                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  );
}


export default ForgotPassword;

