import React, { useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { isNumeric, isLength } from 'validator';
import { getPublisher, uploadPdfEbook, uploadFileEbook, updateEbookCoverPath, updateEbookPDFPath, uploadFileCoverImage, insertEbookDetails, getEbookPrice, getEbookCategory, getPublisherTag, getPublisherKeyword, getEbookSerie } from '../../api'
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Alert } from 'react-bootstrap';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import {  setCurrentManageBookPage} from '../../actions/site.action.js'; 

const AddBooks = () => {
    const dispatch = useDispatch();
    dispatch(setCurrentManageBookPage(1))
    const [selectedDate, handleDateChange] = React.useState(new Date());
    const [selectedDateTime, setSelectedDateTime] = useState(new Date());
    const [checkButtonLoading, setCheckButtonLoading] = useState('1');
    const [showAlertError, setShowAlertError] = useState(false);
    const [showUploadImageErrorMessage, setUploadImageErrorMessage] = useState('');
    const [showUploadPdfErrorMessage, setUploadPdfErrorMessage] = useState('');
    const [uploadEBook, setUploadEBook] = useState('');
    const [uploadEBookName, setUploadEBookName] = useState('');
    const [selectedFileCoverImage, setSelectedFileEbook] = useState('');
    const [tags, setTags] = useState([]);
    const [Keywords, setKeywords] = useState([]);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [selectedPdfName, setSelectedPdfName] = useState('');
    const [CoverPriceError, setCoverPriceError] = useState('');
    const [selectedOnlyOver, setOnlyOver] = useState('');
    const [PricingAndSales, setPricingAndSales] = useState('');
    const [publishOption, setPublishOption] = useState('as_review_pass');
    //Get ราคา EbookPrice
    const [optionsgetEbookPrice, setOptionsgetEbookPrice] = useState([]);
    //getEbookCategory
    const [optionsgetEbookCategory, setOptionsgetEbookCategory] = useState([]);
    //getPublisherKeyword
    const [getPublisherKeywords, setgetPublisherKeyword] = useState([]);
    //getPublisherTag
    const [getPublisherTags, setgetPublisherTag] = useState([]);
    const [otherSeries, setOtherSeries] = useState('0');
    //getEbookSerie
    const [getEbookSeries, setgetEbookSerie] = useState([]);
    const [formData, setFormData] = useState('');
    const [validated, setValidated] = useState(false);

    const uploadImageRef = useRef();
    const uploadPDFRef = useRef();

    const handleTagsChange = (tags) => {
        setTags(tags);
    };

    const customPlaceholder = 'Add a keywords';

    const handleKeywords = (tags) => {
        setKeywords(tags);
    };

    const [rightToLeft, setRightToLeft] = useState('0');

    const handleChangeRightToLeft = () => {
         const newValue = rightToLeft === '1' ? '0' : '1';
        setRightToLeft(newValue);

    }

    const handleImageChange = (event) => {
        const acceptedTypes = ["image/jpeg", "image/jpg"];
        const file = event.target.files[0];

        if (!acceptedTypes.includes(file.type)) {
            // alert("ไฟล์รูปภาพต้องเป็น .jpg, .jpeg, หรือ .png เท่านั้น");
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: "Supported file extensions .jpg .jpeg only",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            // เคลียร์ input
            uploadImageRef.current.value = null;
            return;
        }

        setSelectedFileEbook(file);
        setUploadEBook(URL.createObjectURL(file));
        setUploadEBookName(file.name);
    };

    const handlePdfChange = (event) => {
        const file = event.target.files[0];
        const fileType = file.type;

        if (fileType !== "application/pdf") {
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: "Supported file extension .pdf only",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            // เคลียร์ input
            uploadPDFRef.current.value = null;
          return;
        }
        setSelectedPdfName(file);
        // setSelectedPdf(URL.createObjectURL(file));
    };

    const handleChange = (event) => {
        const { name, value } = event.target || '';


        if(name === 'paperCoverPrice' && parseInt(value) < 0) {
           return;
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
        }


        if (name === 'paperCoverPrice') {
            if (!isNumeric(value) && value != '' && value != null) {
                setCoverPriceError('Please enter numbers only.');
            }

            // else if (!isLength(value, { min: 1, max: 3 })) {
            //     setCoverPriceError('Please enter 3 digits');
            // } else {
            //     setCoverPriceError('');
            // }
        }
    }

    const handleChangeOnlyOver = () => {

        // const result = window.confirm("The system does not currently support books with content 18+");
        // if (result) {
        //     // setOnlyOver((prevValue) => (prevValue === '1' ? '0' : '1'));
        // } else {
        // }

        dispatch(dialogConfirmSetData({
            title: "Error",
            message: "The system does not currently support books with content 18+",
            closeButton: "",
            confirmButton: "OK",
            eventConfirmButton: () => null,
            eventCloseButton: () => null,
            showCloseButton: false
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    };

    const handlePricingAndSales = (event) => {
        setPricingAndSales(event.target.value);

    };

    const handleStatus = (event) => {
        setPublishOption(event.target.value);
        // console.log(event.target.value);

    };

    function showEbookPrice() {
        getEbookPrice().then(handleShowEbookPrice)
    }
    const handleShowEbookPrice = async (response) => {
        setOptionsgetEbookPrice(response.data);
    }

    function showEbookCategory() {
        getEbookCategory().then(handleShowEbookCategory)
    }
    const handleShowEbookCategory = async (response) => {
        setOptionsgetEbookCategory(response.data);
    }

    function showGetPublisherKeyword() {
        getPublisherKeyword().then(handleshowGetPublisherKeyword)
    }
    const handleshowGetPublisherKeyword = async (response) => {
        setgetPublisherKeyword(response.data);
    }

    function showGetPublisherTag() {
        getPublisherTag().then(handleshowGetPublisherTag)
    }
    const handleshowGetPublisherTag = async (response) => {
        setgetPublisherTag(response.data);
    }


    const setKeywordsItem = (keyword) => {
        if (!Keywords.includes(keyword)) {
            setKeywords((prevKeywords) => [...prevKeywords, keyword]);
        }
    };


    const setTagsItem = (Tags) => {
        if (!tags.includes(Tags)) {
            setTags((prevKeywords) => [...prevKeywords, Tags]);
        }
    };

    const handleChangeOtherSeries = (event) => {
        setOtherSeries(event.target.value);
    }

    function showgetEbookSerie() {
        getEbookSerie().then(handleshowgetEbookSerie)
    }
    const handleshowgetEbookSerie = async (response) => {
        setgetEbookSerie(response.data);
    }

    useEffect(() => {
        setPublishOption('as_review_pass');
        setPricingAndSales('for_sale');
        showEbookPrice();
        showEbookCategory();
        setOnlyOver('0');
        showGetPublisherTag();
        showGetPublisherKeyword();
        setOtherSeries('0');
        showgetEbookSerie();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        //#####Upload E-Book Cover
        //uploadEBookName
        const bookTitle = formData.bookTitle || "";
        const authorName = formData.authorName || "";
        const category = formData.cateGory || "";
        //otherSeries
        const otherSeriesName = formData.otherSeriesName || "";
        const volume = formData.volume || "";

        //selectedOnlyOver
        const synopsis = formData.synopsis || "";
        const bookType = formData.bookType || "";
        const isbn = formData.isbn || "";
        const isbnEbook = formData.isbnEbook || "";
        //tags
        //Keywords

        //####Pricing and Sales
        //PricingAndSales
        const paperCoverPrice = formData.paperCoverPrice || '0';
        const paperBookPrice = formData.paperBookPrice || '9';
        //selectedPdfName

        //####Status
        //const publicDate = formData.publicDate || '';

        // const timestamp = selectedDate || null;
        // const date = new Date(timestamp);
        // const publicDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;


        let publicDate = null;
        const timestamp = selectedDate || null;
        if(publishOption == 'schedule'){
            const date = new Date(timestamp);
            publicDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`; 
        }

 
        const noteTeam = formData.noteTeam || "";
        const form = event.currentTarget;

        const _getPublisher = async () => {
            try {
                const response = await getPublisher();
                // console.log('xxxx' + response.data['result']['publisherID']);
                return response.data['result']['publisherID'];
            } catch (error) {
                // console.error("Error while getting publisher:", error);
                // Handle the error or return a default value
                return '';
            }
        };

        if (form.checkValidity() === true) {
            setCheckButtonLoading('2');
            event.preventDefault();
            event.stopPropagation();
            
            let publisherId = "";

            let getPublisherResult = await _getPublisher();
            publisherId = getPublisherResult;

            insertEbookDetails(uploadEBookName, bookTitle, authorName, category, otherSeries, otherSeriesName, selectedOnlyOver, synopsis, bookType, isbn, isbnEbook, tags, Keywords,
                PricingAndSales, paperCoverPrice, paperBookPrice,
                publishOption, publicDate, noteTeam, volume , rightToLeft)
            .then((response) => handleAfterInsertEbook(response, publisherId));

        } else {

        }
        setValidated(true);
    }



    async function handleAfterInsertEbook(response, publisherId) {
        let ebookDetailsID = response.data;
        // console.log(response.data);
        // console.log("xxxx" + selectedPdfName + "hhhhh" + selectedFileCoverImage, 'ebookDetailsID' + ebookDetailsID);
        // console.log("start upload");
        let uploadResult = await uploadBookResource(publisherId, ebookDetailsID);
        // console.log(uploadResult);
        // console.log("end upload flow");

        if (uploadResult['isUploadImageError'] == true && uploadResult['isUploadPdfError'] == true) {
            setCheckButtonLoading('1');
            setUploadImageErrorMessage(uploadResult['uploadImageErrorMessage']);
            setUploadPdfErrorMessage(uploadResult['uploadPdfErrorMessage']);
            setUploadEBook('');
            setSelectedPdf(null);
            // setShowAlertError(true);

            // const result = window.confirm(uploadResult['uploadImageErrorMessage'] + '\n' + uploadResult['uploadPdfErrorMessage']);
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: uploadResult['uploadImageErrorMessage'] +  " \n " + uploadResult['uploadPdfErrorMessage'],
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            // if (result) {
            //     navigate('/EditEbooks', {
            //         state: {
            //             ebookDetailsID: ebookDetailsID
            //         },
            //     });
            // }


        }
        else if (uploadResult['isUploadImageError'] == true) {
            setCheckButtonLoading('1');
            setUploadImageErrorMessage(uploadResult['uploadImageErrorMessage']);
            setUploadPdfErrorMessage('');
            setUploadEBook('');
            // setShowAlertError(true);

            // const result = window.confirm(uploadResult['uploadImageErrorMessage']);
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: uploadResult['uploadImageErrorMessage'],
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            // if (result) {
            //     navigate('/EditEbooks', {
            //         state: {
            //             ebookDetailsID: ebookDetailsID
            //         },
            //     });
            // }


        }
        else if (uploadResult['isUploadPdfError'] == true) {
            setCheckButtonLoading('1');
            setUploadPdfErrorMessage(uploadResult['uploadPdfErrorMessage']);
            setUploadImageErrorMessage('');
            setSelectedPdf(null);
            // setShowAlertError(true);
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: uploadResult['uploadPdfErrorMessage'],
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));

            // const result = window.confirm(uploadResult['uploadPdfErrorMessage']);
            // if (result) {
            //     navigate('/EditEbooks', {
            //         state: {
            //             ebookDetailsID: ebookDetailsID
            //         },
            //     });
            // }


        } else {
            navigate('/ManageBooks', { replace: true });
            // window.location.reload();
        }



    }

    async function uploadBookResource(publisherId, ebookDetailsID) {

        var uploadCoverImagePath = null;
        var uploadSamplePdfPath = null;
        var uploadMainPdfPath = null;

        var isUploadCoverError = false;
        var uploadCoverErrorMessage = "";

        var isUploadPdfError = false;
        var uploadPdfErrorMessage = "";

        // ตรวจสอบที่ selectedFileEbook และ selectedPdfName ไม่เท่ากับ ''
        if (selectedFileCoverImage != '') {
            // console.log("before image cover : ");
            const uploadFilePromise = uploadFileEbook(selectedFileCoverImage, ebookDetailsID, publisherId)
                .then(async response => {

                    if (response.data['access_path'] != null && response.data['error'] != true) {
                        uploadCoverImagePath = response.data['access_path'];
                        await updateEbookCoverPath(uploadCoverImagePath, ebookDetailsID);

                    } else {
                        isUploadCoverError = true;
                        uploadCoverErrorMessage = "We're sorry, but it looks like there's an issue with the file you uploaded. Please ensure you're uploading a valid file with the correct format";
                    }

                });

            // console.log("start image upload");
            await Promise.all([uploadFilePromise]);
            // console.log("end image upload");
        }

        if (selectedPdfName != '') {
            const uploadPdfPromise = uploadPdfEbook(selectedPdfName, ebookDetailsID, publisherId)
                .then(async response => {

                    if (response.data['error'] == false) {
                        uploadSamplePdfPath = "uploaded";
                        uploadMainPdfPath = "uploaded";

                        await updateEbookPDFPath(uploadMainPdfPath, uploadSamplePdfPath, ebookDetailsID);

                    } else {
                        isUploadPdfError = true;
                        uploadPdfErrorMessage = "We're sorry, but it looks like there's an issue with the file you uploaded. Please ensure you're uploading a valid file with the correct format";
                    }

                });

            // console.log("start pdf upload");
            await Promise.all([uploadPdfPromise]);
            // console.log("end pdf upload");

        }

        if (isUploadPdfError || isUploadCoverError) {

            let result = {
                "isUploadPdfError": isUploadPdfError,
                "isUploadImageError": isUploadCoverError,
                "uploadPdfErrorMessage": uploadPdfErrorMessage,
                "uploadImageErrorMessage": uploadCoverErrorMessage
            };

            return result;
        } else {

            let result = {
                "isUploadPdfError": false,
                "isUploadImageError": false
            };

            return result;

        }
    }


    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/ManageBooks');
        // console.log('dsdsadsads');
    };

    const handleBackClick = () => {
        navigate('/ManageBooks', { replace: true });
    };

    // const handleClickPdf = () => {
    //     window.open(selectedPdf, '_blank');
    // };

    const deleteFilePdf = () => {
        // const result = window.confirm("Confirm clear: This will remove the selected file from the upload.");
        // if (result) {
        //     setSelectedPdf("");
        // } else {
        // }
        dispatch(dialogConfirmSetData({
            title: "Confirm clear",
            message: "This will remove the selected file from the upload.",
            closeButton: "Cancel",
            confirmButton: "Remove",
            eventConfirmButton: () => setSelectedPdf(null),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
    }


    const deleteProFileCover = () => {
        // const result = window.confirm("Confirm clear: This will remove the selected file from the upload.");
        // if (result) {
        //     setUploadEBook("");
        // } else {
        // }

        dispatch(dialogConfirmSetData({
            title: "Confirm clear",
            message: "This will remove the selected file from the upload.",
            closeButton: "Cancel",
            confirmButton: "Remove",
            eventConfirmButton: () => setUploadEBook(""),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    }

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <span className='title-text'> <div className='title-content-text' onClick={() => handleBackClick()}>Manage Books</div> <div style={{marginLeft: '10px',marginRight: '10px'}}>{'›'}</div>Add E-Books</span>
                            <p className='context-text'>Add your new E-Book.</p>

                            <Card className='card-box card-box-top-pading'>
                                <Card.Body>
                                    <div className='content-box'>
                                        <span className='inner-title-text'>Book Details</span>
                                        <hr className='hr-title' />
                                        <Form noValidate validated={validated} onSubmit={handleSubmit}>


                                            <Form.Group as={Row} className="mb-12" controlId="">
                                                <Form.Label column sm="4">
                                                    <div style={{ paddingBottom: '0px' }}>
                                                        <span className='my-rowlabel'>Upload E-Book Cover<span className='star' >*</span> </span>
                                                    </div>
                                                </Form.Label>
                                                <Col sm="6" style={{ paddingTop: '10px' }}>
                                                    {(uploadEBook) ?
                                                        <>
                                                            <div className="image-frame" >
                                                                <img src={uploadEBook} alt="Selected" style={{ maxHeight: '250px' }} />
                                                            </div>
                                                            <div>
                                                                <span className='text-alerts' ></span>
                                                            </div>
                                                            <a onClick={() => deleteProFileCover()}
                                                                className='delete-link link-button'>
                                                                Delete
                                                            </a>
                                                        </>
                                                        :
                                                        <div>
                                                            <Form.Control ref={uploadImageRef} required type="file" accept="image/jpeg" value={formData.uploadEBook} name="uploadEBook" onChange={handleImageChange} />
                                                            <div>
                                                                <span className='text-alerts' >Supported file extensions .jpg .jpeg only</span>
                                                            </div>
                                                        </div>
                                                    }

                                                </Col>
                                            </Form.Group>
                                            <br></br>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Title <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control required type="text" maxLength={200} value={formData.bookTitle} name="bookTitle" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Author Name <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control required type="text" maxLength={255} value={formData.authorName} name="authorName" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Category <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Select required value={formData.cateGory} name="cateGory" onChange={handleChange} >
                                                        <option value=''>Please Select</option>
                                                        {optionsgetEbookCategory.map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name_th}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">Book Series<span className='star' >*</span></Form.Label>
                                                <Col sm="6">
                                                    <Form.Select required value={otherSeries} name="otherSeries" onChange={handleChangeOtherSeries}>
                                                        <option value='0' >Other Series</option>
                                                        {getEbookSeries.map((option) => (
                                                            <option key={option.serie_id} value={option.serie_id}>
                                                                {option.serie_name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>

                                                </Col>
                                            </Form.Group>


                                            {otherSeries === '0' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            <Form.Control required aria-label="New Book series" maxLength={100} placeholder="New Book series" type="text" value={formData.otherSeriesName} name="otherSeriesName" onChange={handleChange} />
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            )}

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control required aria-label="Order of books in this series" placeholder="Order of books in this series" type="number" min={1} name="volume" onChange={handleChange} />
                                                    <span className='text-alerts' >Order of books in this series</span>
                                                </Col>

                                            </Form.Group>



                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Only for People Over 18 Years Old
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Check
                                                        label="Yes"
                                                        type='checkbox'
                                                        name='onlyOver'
                                                        value='1'
                                                        onChange={handleChangeOnlyOver}
                                                        inline
                                                        checked={selectedOnlyOver === '1'}
                                                    />

                                                    {selectedOnlyOver === '1' && (
                                                        <span className='text-alertsStar' >The system does not currently support books with content 18+</span>
                                                    )}
                                                </Col>

                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Synopsis
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control
                                                        value={formData.synopsis}
                                                        name="synopsis"
                                                        maxLength={1000}
                                                        onChange={handleChange}
                                                        as="textarea"
                                                        placeholder=""
                                                        style={{ height: '100px' }}
                                                    />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">Book Type<span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Select required value={formData.bookType} name="bookType" onChange={handleChange} >
                                                        <option value=''>Please Select</option>
                                                        <option value="1" >Thai Book</option>
                                                        <option value="2" >Translated Book</option>
                                                    </Form.Select>
                                                </Col>
                                            </Form.Group>



                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control type="text" maxLength={30} value={formData.isbn} name="isbn" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>



                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN E-Book
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control type="text" maxLength={30} value={formData.isbnEbook} name="isbnEbook" onChange={handleChange} />
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                                Right-to-left reading style
                                                </Form.Label>
                                                <Col sm="6">

                                                    <Form.Check
                                                        type='checkbox'
                                                        name='rightToLeft'
                                                        value='1'
                                                        onChange={handleChangeRightToLeft}
                                                        inline
                                                        checked={rightToLeft === '1'}
                                                    />
                                    
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4" >
                                                    Tags
                                                </Form.Label>
                                                <Col sm="6" style={{ border: '1px solid #ced4da', borderRadius: '6px', marginLeft: '10px', width: '48.5%', paddingTop: '10px' }}>
                                                    <TagsInput className='input-tag-custom' required value={tags} onChange={handleTagsChange} addOnBlur={true} addOnPaste={true} onlyUnique={true} />

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-button'>
                                                        {getPublisherTags.map((option) => (
                                                            (!tags.includes(option.tag_name)) ?
                                                                <a key={option.tag_name} style={{ marginRight: '2px', color: '#fff' }} onClick={() => setTagsItem(option.tag_name)} className="my-button"> <span className='font-tags'>{option.tag_name} +</span></a>
                                                                : <></>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Keywords
                                                </Form.Label>
                                                <Col sm="6" style={{ border: '1px solid #ced4da', borderRadius: '6px', marginLeft: '10px', width: '48.5%', paddingTop: '10px' }}>
                                                    <TagsInput className='input-keyword-custom' required value={Keywords} onChange={handleKeywords} placeholder={customPlaceholder} addOnBlur={true} addOnPaste={true} onlyUnique={true} inputProps={{ placeholder: customPlaceholder }} />

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-button'>
                                                        {getPublisherKeywords.map((option) => (
                                                            (!Keywords.includes(option.keyword_name)) ?
                                                                <a key={option.keyword_name} style={{ marginRight: '2px', color: '#fff' }} onClick={() => setKeywordsItem(option.keyword_name)} className="my-button-keyboard"> <span className='font-tags'>{option.keyword_name} + </span></a>
                                                                : <></>
                                                        ))}
                                                    </div>
                                                </Col>
                                            </Form.Group>


                                            <br></br>
                                            <span className='inner-title-text'>Pricing and Sales</span>
                                            <hr className='hr-title' />
                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Type Sales <span className='star' >*</span>
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Check
                                                        label="Books for Sale"
                                                        type='radio'
                                                        name='PricingAndSales'
                                                        value='for_sale'
                                                        onChange={handlePricingAndSales}
                                                        inline
                                                        checked={PricingAndSales === 'for_sale'}

                                                    />

                                                    <Form.Check
                                                        label="Free Books"
                                                        type='radio'
                                                        name='PricingAndSales'
                                                        value='free'
                                                        onChange={handlePricingAndSales}
                                                        inline
                                                        checked={PricingAndSales === 'free'}

                                                    />
                                                </Col>
                                            </Form.Group>
                                            {PricingAndSales === 'for_sale' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Paper Cover Price  <span className='star' >*</span>
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            <Form.Control required type="text" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }}  min="0"  isInvalid={!!CoverPriceError} value={formData.paperCoverPrice} name="paperCoverPrice" onChange={handleChange}>
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                {CoverPriceError}
                                                            </Form.Control.Feedback>
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Book Price  <span className='star' >*</span>
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            <Form.Select 
                                                                required={PricingAndSales === 'for_sale'}
                                                                 value={formData.paperBookPrice} name="paperBookPrice" onChange={handleChange}>
                                                                <option value=''>Please Select</option>
                                                                {optionsgetEbookPrice.map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.price}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                        </Col>
                                                    </Form.Group>

                                                </div>
                                            )}
                                            <br></br>
                                            <span className='inner-title-text'>E-Book File</span>
                                            <hr className='hr-title' />

                                            <Form.Group as={Row} className="mb-3" controlId="">

                                                <Form.Label column sm="4">
                                                    <div style={{ paddingBottom: '60px' }} >
                                                        <span className='my-rowlabel'>  Upload E-Book File <span className='star' >*</span> </span>
                                                    </div>
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(selectedPdf) ?
                                                        <div >
                                                             <Form.Label column sm="4">
                                                               There is a file uploaded
                                                                 </Form.Label>
                                                            <a onClick={() => deleteFilePdf()}
                                                                className='delete-link link-button'>
                                                                Delete
                                                            </a>
                                                        </div>
                                                        :
                                                        <div>
                                                            <Form.Control ref={uploadPDFRef} required type="file" accept=".pdf" onChange={handlePdfChange} />
                                                            <div>
                                                                <span className='text-alerts' >Supported file extension .pdf only</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <br></br>
                                            <span className='inner-title-text'>On-sale date</span>
                                            <hr className='hr-title' />
                                            <Form.Group as={Row} className="mb-12" controlId="">
                                                <Form.Label column sm="4">
                                                    <span className='my-rowlabel'>Status<span className='star' >*</span></span>
                                                </Form.Label>
                                                <Col >
                                                    <Form.Check
                                                        label="As soon as review pass"
                                                        type='radio'
                                                        name='publishOption'
                                                        value='as_review_pass'
                                                        checked={publishOption === 'as_review_pass'}
                                                        onChange={handleStatus}
                                                        inline

                                                    />

                                                    <Form.Check
                                                        label="Scheduled"
                                                        type='radio'
                                                        name='publishOption'
                                                        value='schedule'
                                                        checked={publishOption === 'schedule'}
                                                        onChange={handleStatus}
                                                        inline

                                                    />
                                                </Col>
                                            </Form.Group>

                                            <div style={{ paddingTop: '10px' }}></div>

                                            {publishOption === 'schedule' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Publish Date <span className='star' >*</span>
                                                        </Form.Label>
                                                        <Col sm="6" className='date-time-input'>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DateTimePicker']}>
                                                                    <DateTimePicker
                                                                        label=""
                                                                        isBeforeDay={true}
                                                                        onChange={handleDateChange}
                                                                        format="DD/MM/YYYY HH:mm"
                                                                        ampm={false}
                                                                        defaultValue={dayjs(selectedDate)} />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            )}



                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                Note to Reviewers
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Control as="textarea" rows="8" maxLength={200} value={formData.noteTeam} name="noteTeam" onChange={handleChange} />

                                                </Col>
                                            </Form.Group>

                                            <br></br>

                                            <center>

                                                <div>
                                                    {showAlertError && (
                                                        <Alert variant="danger" onClose={() => setShowAlertError(false)} dismissible>
                                                            <Alert.Heading>Error</Alert.Heading>
                                                            <p>
                                                                {showUploadImageErrorMessage + showUploadPdfErrorMessage}
                                                            </p>
                                                        </Alert>
                                                    )}
                                                </div>

                                                {checkButtonLoading == '1' && (
                                                    <Button className='button-form-cancel button-main button-white' onClick={handleCancel} variant="primary" >Cancel</Button>
                                                )}

                                                {checkButtonLoading == '1' && (
                                                    <Button className='button-form-save button-main button-orange' variant="primary" type="submit">Save</Button>
                                                )}

                                                {checkButtonLoading == '2' && (
                                                    <Spinner animation="border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                )}

                                            </center>
                                        </Form>
                                    </div>



                                </Card.Body>
                            </Card>
                        </div>


                    </main>
                </Col>


            </Row>
        </div >
    );
};

export default AddBooks;
