
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Button from 'react-bootstrap/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { insertPagesShelf, getPagesSelf , editPagesShelf } from '../../api/index.js'

const ManagePageShelf = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pagesTabId } = location.state || {};
    const [validated, setValidated] = useState(false);
    const [status, setStatus] = useState('0');

    useEffect(() => {
        if (pagesTabId != undefined) {
            showPagesShelfDetails();
        }
    }, []);

    const [pagesSelfDetails, setPagesSelfDetails] = useState([]);
    const useTabName = useRef();


    const handleBackClick = (url) => {
        navigate('/'+url, { replace: true });
     };

    const handleStatus = (event) => {
        setStatus(event.target.value);
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const tabName = useTabName.current.value;

        if (form.checkValidity() === true) {
            event.preventDefault();
            event.stopPropagation();

            if (pagesTabId == 'undefined' || pagesTabId == '' || pagesTabId == null || pagesTabId == undefined) {
                insertPagesShelf(tabName, status).then(response => {
                    navigate('/PagesShelf', { replace: true });
                });
            } else {
                editPagesShelf(tabName, status , pagesTabId).then(response => {
                    navigate('/PagesShelf', { replace: true });
                });

            }

        }
        setValidated(true);

    }

    function showPagesShelfDetails() {
        getPagesSelf(pagesTabId).then(response => {
            setPagesSelfDetails(response.data);
            setStatus(response.data[0]['tab_active']);
        });
    }

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <Row>
                                <div className='col-md-12' >
                                    <Row>
                                        <div className='col-md-6'>

                                            <span className='title-text'>
                                                <div className='title-content-text' onClick={() => handleBackClick('PagesShelf')}>Pages</div>
                                                &nbsp;&nbsp; {' › '} &nbsp;&nbsp;
                                                {pagesTabId == null || pagesTabId === '' ? (
                                                        'Add'
                                                    ) : (
                                                        'Edit'
                                                    )}
                                                &nbsp;&nbsp;
                                                {' '}Page
                                            </span>

                                        </div>
                                        <div className='col-md-6 col-6-div' style={{ textAlign: 'right' }}>
                                            <Row>
                                                <Col sm={5} >
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                            <br></br>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col className='' >
                                            <main>
                                                <div className='box-table-Account2'>
                                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Page Title
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <Form.Control required type="text" maxLength={45} ref={useTabName} defaultValue={(pagesSelfDetails.length > 0 && pagesSelfDetails[0].tab_name) || ''} name="useTabName" />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-12" controlId="">
                                                            <Form.Label column sm="4">Status</Form.Label>
                                                            <Col >
                                                                <Form.Check
                                                                    label="Active"
                                                                    type='radio'
                                                                    name='Status'
                                                                    value='1'
                                                                    checked={status === '1'}
                                                                    onChange={handleStatus}
                                                                    inline
                                                                />

                                                                <Form.Check
                                                                    label="Inactive"
                                                                    type='radio'
                                                                    name='Status'
                                                                    value='0'
                                                                    checked={status === '0'}
                                                                    onChange={handleStatus}
                                                                    inline
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <br />
                                                        <center>
                                                            <Button className='button-form-cancel button-main button-white' onClick={() => handleBackClick('PagesShelf')} variant="primary">Cancel</Button>
                                                            <Button className='button-form-save button-main button-orange' type="submit">Save</Button>
                                                        </center>

                                                    </Form>

                                                </div>

                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>

                        </div>

                    </main>


                </Col>


            </Row>
        </div >
    );
};

export default ManagePageShelf;
