import React, { useState } from 'react';
import { Routes, Route, Navigate,Outlet } from 'react-router-dom';
import './css/app.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ManageBooks from './containers/user/ManageBooks.js';
import AccountSettings from './containers/user/AccountSettings.js';
import LogIn from './containers/login/LogIn.js';
import SignUpInfoPersonal from './containers/login/SignUpInfoPersonal.js';
import ForgotPassword from './containers/login/ForgotPassword.js'
import SignUp from './containers/login/SignUp.js';
import Financial from './containers/user/Financial.js';
import AddBooks from './containers/Manage/AddBooks';
import EditEbooks from './containers/Manage/EditEbooks';
import ViewEbooks from './containers/Manage/ViewEbooks';
import BooksApproval from './containers/admin/BooksApproval';
import Categories from './containers/admin/Categories';
import AddCategories from './containers/admin/AddCategories';
import EditCategories from './containers/admin/EditCategories';
import ViewEbooksAdmin from './containers/admin/ViewEbooksAdmin';
import ShelfManagement from './containers/admin/ShelfManagement.js';
import PagesShelf from './containers/admin/PagesShelf.js';
import ManagePageShelf from './containers/admin/ManagePageShelf.js';
import ManageReview from './containers/admin/ManageReview.js';
import Report from './containers/user/Report';
import ViewFinancial from './containers/user/ViewFinancial.js';
import Banner from './containers/admin/Banner.js';
import ManageShelf from './containers/admin/ManageShelf.js';
import AddBanner from './containers/admin/AddBanner.js';
import CustomerSupport from './containers/admin/CustomerSupport.js';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getProfile,checkWebCredentialExist } from './api/index.js'
import { useDispatch, useSelector } from 'react-redux';
import { userSetProfile, userSetLevel, userSetName } from './actions';
import Contract from './containers/user/Contract.js';
import { checkUserCanViewPage, isUserLogedIn } from './helper/Helper.js';
import PageNotFound from './containers/Manage/PageNotFound.js';
import TermOfUsePage from './containers/term-privacy/TermOfUsePage.js';
import PrivacyPolicyPage from './containers/term-privacy/PrivacyPolicyPage.js';

//UserContext
const App = () => {

    // Define the allowed routes for each user level
    const allowedRoutes = {
        admin: [
          '/BooksApproval',
          '/Categories',
          '/AddCategories',
          '/EditCategories',
          '/ViewEbooksAdmin',
          '/Banner',
          '/AddBanner',
          '/ShelfManagement',
          '/ManageShelf',
          '/PagesShelf',
          '/ManagePageShelf',
          '/ManageReview',
          '/CustomerSupport'
        ],
        guest: [
          '/ManageBooks',
          '/AccountSettings',
          '/Financial',
          '/Report',
          '/AddBooks',
          '/EditEbooks',
          '/ViewEbooks',
          '/ViewFinancial',
          '/Contract',
        ]
    };

    const publicRoutes = [
        '/',
        '/SignUp',
        '/ForgotPassword',
        '/SignUpInfo-Personal',
        '/PageNotFound',
        '/term-of-use',
        '/privacy-policy'
    ];

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuthenticated = isUserLogedIn();
    const userLevel = useSelector(state => state.user.userLevel);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // ทุกครั้งที่เปิดหรือ refresh หน้าเว็บ จะทำการเรียก profile ก่อนเสมอเพื่อเช็ค user ก่อนโหลดหน้าเว็บ
        async function process() {

            try{

                let checkWebCredentialExistResponse = await checkWebCredentialExist()

                // กรณีที่ยังไม่มี Credentail
                if( checkWebCredentialExistResponse.result === false ){

                    if(!publicRoutes.includes(window.location.pathname)){
                        navigate('/', { replace: true });
                        //กำหนดวันที่หมดอายุของ Cookie ให้เป็นวันที่ผ่านมาแล้ว (วันที่ 1 มกราคม 1970 เวลา 00:00:01 GMT) ซึ่งจะทำให้ Cookie นั้นหมดอายุและถูกลบทิ้ง.
                        document.cookie = 'ebookCMSUserLogin' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                    }

                }else{
                    const getProfileResponse = await getProfile();

                    // alert("user level ===>" + level)
                    if (getProfileResponse.data.error === true) {
                        if(!publicRoutes.includes(window.location.pathname)) {
                            navigate('/', { replace: true });
                            document.cookie = 'ebookCMSUserLogin' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                        } 
                        // window.location.replace('/');
                        // window.location.reload()
                    } else {
                        let level = "";
                        if (getProfileResponse && getProfileResponse.data && getProfileResponse.data.profile && getProfileResponse.data.profile.level) {
                            level = getProfileResponse.data.profile.level;
                            // console.log(level);
                        }
                        dispatch(userSetProfile(getProfileResponse.data.profile));
                        dispatch(userSetName(getProfileResponse.data.profile.name));
                        dispatch(userSetLevel(level));
                       
                        // dispatch(user)
                    }
                }

            } catch(e) {
                // สำหรับ Handle Promise Reject 
            } finally {
                setLoading(false);
            }

        }

        process();
        
    }, [dispatch, navigate])

    const PrivateRoute = ({ isAuthenticated }) => {
        // return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;

        if (!isAuthenticated) {
            // If not authenticated, redirect to the login page
            return <Navigate to="/" />;
        }

        // Check if the current route is allowed for the user level
        const isRouteAllowed = allowedRoutes[userLevel] && allowedRoutes[userLevel].some(route => window.location.pathname.startsWith(route));

        // If the route is allowed, render the child routes; otherwise, redirect to the default page
        return isRouteAllowed ? (
            <Outlet />
        ) : (
            <Navigate to={userLevel === 'admin' ? '/BooksApproval' : '/ManageBooks'} />
        );
    };

    if (loading) {
        return <div></div>;
    }


    return (
    
        <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to={userLevel === 'admin' ? '/BooksApproval' : '/ManageBooks'} /> : <LogIn />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/SignUpInfo-Personal" element={<SignUpInfoPersonal />} />
            <Route path="*" element={<PageNotFound />}/>
            <Route path="/PageNotFound" element={<PageNotFound />}/>
            <Route path="/term-of-use" element={<TermOfUsePage />}/>
            <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>


            <Route element={<PrivateRoute isAuthenticated={isAuthenticated}  />}>
                {/* For User */}
                <Route path="/ManageBooks" element={<ManageBooks />} />
                <Route path="/AccountSettings" element={<AccountSettings />} />
                <Route path="/Financial" element={<Financial />} />
                <Route path="/AddBooks" element={<AddBooks />} />
                <Route path="/EditEbooks" element={<EditEbooks />} />
                <Route path="/ViewEbooks" element={<ViewEbooks />} />
                <Route path="/ViewFinancial" element={<ViewFinancial />} />
                <Route path="/Contract" element={<Contract />} />

                {/* Admin*/}
                <Route path="/BooksApproval" element={<BooksApproval />} />
                <Route path="/Categories" element={<Categories />} />
                <Route path="/AddCategories" element={<AddCategories />} />
                <Route path="/EditCategories" element={<EditCategories />} />
                <Route path="/ViewEbooksAdmin" element={<ViewEbooksAdmin />} />
                <Route path="/Report" element={<Report />} />
                <Route path="/Banner" element={<Banner/>}/>
                <Route path="/AddBanner" element={<AddBanner/>}/>
                <Route path="/ShelfManagement" element={<ShelfManagement/>}/>
                <Route path="/ManageShelf" element={<ManageShelf/>}/>
                <Route path="/PagesShelf" element={<PagesShelf/>}/>
                <Route path="/ManagePageShelf" element={<ManagePageShelf/>}/>
                <Route path="/ManageReview" element={<ManageReview/>}/>
                <Route path="/CustomerSupport" element={<CustomerSupport/>}/>
                
            </Route>
            
        </Routes>
     
    );
}
export default App;

