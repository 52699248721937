import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Check, Container, Row, Col } from 'react-bootstrap';
import HeadOnlyLanguageSelection from '../../components/navbar/HeadOnlyLanguageSelection.js';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import { getProvince, getBank, updatePublisherDetail, getBankAccount, uploadFileCompany, updateCompanyCertFilePathWithPublisherId, sendVerifyAccountEmailWithoutLogin } from '../../api'
import Alert from 'react-bootstrap/Alert';
import { isNumeric, isLength } from 'validator';
import { hideLoading, showLoading } from '../../helper/Helper.js';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';


function SignupPage() {
  const companyRef = useRef();
  const accountEmailRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedCompanyCertFileIsPDF, setSelectedCompanyCertFileIsPDF] = useState('');
  const [selectedCompanyCertFileName, setSelectedCompanyCertFileName] = useState('');
  const [idCardError, setIdCardError] = useState('');
  const [phoneNumberError, setphoneNumberError] = useState('');
  const [taxIdError, settaxIdError] = useState('');
  const [zipCodeError, setzipCodeError] = useState('');
  const [zipCodeDocmentError, setzipCodeDocmentError] = useState('');
  const [accountNumberError, setaccountNumberError] = useState('');
  const [validated, setValidated] = useState(false);
  const [uploadCompany, setUploadCompany] = useState('');
  const [fileCompanyCert, setFileCompanyCert] = useState('');
  const [showUploadImageErrorMessage, setUploadImageErrorMessage] = useState('');
  const [showAlertError, setShowAlertError] = useState(false);
  const [tempRegisterResult, setTempRegisterResult] = useState(null);
  const { email, password } = location.state || {};

  const [formData, setFormData] = useState({
    penNamePublisher: '',
    nameSurname: '',
    idCard: '',
    phoneNumber: '',
    CorporationName: '',
    taxId: '',
    address: '',
    street: '',
    subdisTrict: '',
    disTrict: '',
    province: '',
    zipCode: '',
    addressDocment: '',
    streetDocment: '',
    subdistrictDocment: '',
    districtDocment: '',
    provinceDocment: '',
    zipCodeDocment: '',
    bank: '',
    accountName: '',
    accountNumber: '',
    accountType: '',
    branch: '',
    Province: '',
    accountingContactName: '',
    accountingPhoneNumber: '',
    accountingEmail: ''
   });
  const navigate = useNavigate();


  const deleteCompany = () => {
    // const result = window.confirm("Confirm clear: This will remove the selected file from the upload.");
    // if (result) {
    //   setUploadCompany("");
    // }
    dispatch(dialogConfirmSetData({
      title: "Confirm clear",
      message: "This will remove the selected file from the upload.",
      closeButton: "Cancel",
      confirmButton: "OK",
      eventConfirmButton: () => setUploadCompany(""),
      eventCloseButton: () => null,
      showCloseButton: true
    }));
    // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));
  }

  const handleClickPdf = () => {
    window.open(uploadCompany, '_blank');
  };

  const handleImageChange = (event) => {

    const acceptedTypes = ["image/jpeg", "image/jpg", "application/pdf"];
    const file = event.target.files[0];

    if (!acceptedTypes.includes(file.type)) {
      // alert("ไฟล์รูปภาพต้องเป็น .jpg, .jpeg, หรือ .png เท่านั้น");
      dispatch(dialogConfirmSetData({
        title: "Error",
        message: "Supported file extensions .jpg .jpeg .pdf only",
        closeButton: "",
        confirmButton: "OK",
        eventConfirmButton: () => null,
        eventCloseButton: () => null,
        showCloseButton: false
      }));
      // ** ทำให้ dialog โชว์ขึ้นมา **
      dispatch(dialogConfirmSetShowModal(true));
      // เคลียร์ input
      companyRef.current.value = null;
      return;
    }

    if (file.type === "application/pdf") {
      setSelectedCompanyCertFileIsPDF(true);
    } else {
      setSelectedCompanyCertFileIsPDF(false);
    }

    setFileCompanyCert(file);
    setSelectedCompanyCertFileName(file.name);
    setUploadCompany(URL.createObjectURL(file));

  };


  const [selectedAddress, setSelectedAddress] = useState('1'); // เก็บสถานะ inputAddress ที่เลือกอยู่
  const handleChangeAddress = (event) => {
    setSelectedAddress(event.target.value);
  };

  // const [selectedFile, setSelectedFile] = useState(null);
  // const [selectedFileName, setSelectedFileName] = useState('');
  // const handleUpfile = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  //   setSelectedFileName(URL.createObjectURL(file));
  // };



  const [acceptAgreement, setAcceptAgreement] = useState(false);
  const handleChangeAccept = (event) => {
    // console.log(event.target.value);
    setAcceptAgreement(event.target.checked);
  }

  const [personalCorporation, setpersonalCorporation] = useState(''); // เก็บสถานะ personalCorporation ที่เลือกอยู่
  const handleChangepersonalCorporation = (event) => {
    setpersonalCorporation(event.target.value);
    // console.log(personalCorporation);
  };

  //Get จังหวัด
  const [optionsProvince, setOptionsProvince] = useState([]);
  const [optionsProvince2, setOptionsProvince2] = useState([]);
  const [optionsProvince3, setOptionsProvince3] = useState([]);

  function showProvince() {
    getProvince().then(handleShowProvinceResponse)
  }
  const handleShowProvinceResponse = async (response) => {
    setOptionsProvince(response.data);
    setOptionsProvince2(response.data);
    setOptionsProvince3(response.data);
  }
  //===========================================================//

  //get ธนาคาร getBank
  const [optionsBank, setOptionsBank] = useState([]);
  function showBank() {
    getBank().then(handleShowBankResponse)
  }
  const handleShowBankResponse = async (response) => {
    setOptionsBank(response.data);
  }
  //===========================================================//

  //get ธนาคาร getBankAccount
  const [optionsgetBankAccount, setOptionsgetBankAccount] = useState([]);
  function showgetBankAccount() {
    getBankAccount().then(handleShowgetBankAccountResponse)
  }
  const handleShowgetBankAccountResponse = async (response) => {
    setOptionsgetBankAccount(response.data);
  }
  //===========================================================//



  useEffect(() => {
    if(email == undefined || email == null || email == ""){
      navigate('/SignUp', {replace: true})
    }else{
      setFormData(prevFormData => ({ ...prevFormData, personalCorporation: '1' }));
      setSelectedAddress('1');
      setpersonalCorporation('2');
      showProvince();
      showBank();
      showgetBankAccount();
    }
    
  }, []);



  //รับค่าจาก ฟอม
  const handleChange = (event) => {
    const { name, value } = event.target || '';
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    // console.log(formData);

    if (name == 'idCard') {
      if (!isNumeric(value)) {
        setIdCardError('Please enter numbers only.');
      } else if (!isLength(value, { min: 13, max: 13 })) {
        setIdCardError('Please enter 13 digits');
      } else {
        setIdCardError('');
      }
    }

    if (name == 'phoneNumber') {
      if (!isNumeric(value)) {
        setphoneNumberError('Please enter numbers only.');
      } else if (!isLength(value, { min: 10, max: 10 })) {
        setphoneNumberError('Please enter 10 digits');
      } else {
        setphoneNumberError('');
      }
    }

    if (name == 'taxId') {
      if (!isNumeric(value)) {
        settaxIdError('Please enter numbers only.');
      } else if (!isLength(value, { min: 13, max: 13 })) {
        settaxIdError('Please enter 13 digits');
      } else {
        settaxIdError('');
      }
    }

    if (name == 'zipCode') {
      if (!isNumeric(value)) {
        // setzipCodeError('Please enter numbers only.');
      } else if (!isLength(value, { min: 5, max: 5 })) {
        setzipCodeError('Please enter 5 digits');
      } else {
        setzipCodeError('');
      }
    }


    if (name == 'zipCodeDocment') {
      if (!isNumeric(value)) {
        // setzipCodeDocmentError('Please enter numbers only.');
      } else if (!isLength(value, { min: 5, max: 5 })) {
        setzipCodeDocmentError('Please enter 5 digits');
      } else {
        setzipCodeDocmentError('');
      }
    }

  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    const penNamePublisher = formData.penNamePublisher || null;
    const nameSurname = formData.nameSurname || null;
    const idCard = formData.idCard || null;
    const phoneNumber = formData.phoneNumber || null;

    const CorporationName = formData.CorporationName || null;
    const taxId = formData.taxId || null;


    const address = formData.address || null;
    const street = formData.street || null;
    const subdisTrict = formData.subdisTrict || null;
    const disTrict = formData.disTrict || null;
    const province = formData.province || null;
    const zipCode = formData.zipCode || null;

    const addressDocment = formData.addressDocment || null;
    const streetDocment = formData.streetDocment || null;
    const subdistrictDocment = formData.subdistrictDocment || null;
    const districtDocment = formData.districtDocment || null;
    const provinceDocment = formData.provinceDocment || null;
    const zipCodeDocment = formData.zipCodeDocment || null;

    const bank = formData.bank || null;
    const accountName = formData.accountName || null;
    const accountNumber = formData.accountNumber || null;
    const accountType = formData.accountType || null;
    const branch = formData.branch || null;
    const provincebank = formData.Province || null;

    const accountingContactName = formData.accountingContactName || null;
    const accountingPhoneNumber = formData.accountingPhoneNumber || null;
    const accountingEmail = formData.accountingEmail || null;

    accountEmailRef.current.setCustomValidity("")
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(formData.accountingEmail)) {
      dispatch(dialogConfirmSetData({
        title: "Invalid Email.",
        message: "Your email address is not valid. Please check for ensure that email follows the standard format.",
        closeButton: "",
        confirmButton: "OK",
        eventConfirmButton: () => null,
        eventCloseButton: () => null,
        showCloseButton: false
      }));
      // ** ทำให้ dialog โชว์ขึ้นมา **
      dispatch(dialogConfirmSetShowModal(true));
      accountEmailRef.current.setCustomValidity("Please enter a valid email address.")
      accountEmailRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    } else {
      showLoading();
      if (form.checkValidity() === true) {
        setShowAlertError(false);
        event.preventDefault();
        event.stopPropagation();

        updatePublisherDetail("new", CorporationName, taxId, selectedAddress, personalCorporation, penNamePublisher, nameSurname, idCard, phoneNumber,
          address, street, subdisTrict, disTrict, province, zipCode,
          addressDocment, streetDocment, subdistrictDocment, districtDocment, provinceDocment, zipCodeDocment,
          bank, accountName, accountNumber, accountType, branch, provincebank, accountingContactName, accountingPhoneNumber, accountingEmail,
          email, password
        ).then((response) => handlePublisherDetailResponse(response));


      } else {
        hideLoading();
        dispatch(dialogConfirmSetData({
          title: "Error",
          message: "Please fill in the information correctly.",
          closeButton: "",
          confirmButton: "OK",
          eventConfirmButton: () => null,
          eventCloseButton: () => null,
          showCloseButton: false
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));
        // setUploadImageErrorMessage("Please fill in the information correctly")
        // setShowAlertError(true);
      }

    }
    setValidated(true);
  }

  const handlePublisherDetailResponse = async (response) => {

    hideLoading();

    var registerResult = null;
    if (response.data["registerResult"] != null) {
      setTempRegisterResult(response.data["registerResult"]);
      registerResult = response.data["registerResult"];
    } else {
      registerResult = tempRegisterResult;
    }

    let publisherId = response.data["publisherId"];

    // error เป็น false คือ register error
    if (response.data["error"] == false) {

      let uploadResult = await uploadCompanyCertPath(publisherId);

      if (uploadResult['isUploadImageError'] == true) {
        setUploadImageErrorMessage(uploadResult['uploadImageErrorMessage']);
        setShowAlertError(true);
        setUploadCompany("");
      } else {

        if (registerResult != null) {
          let title = registerResult["message"]["title"];
          let message = registerResult["message"]["message"];
          let button = registerResult["message"]["button"];
          let verifyToken = registerResult["result"]["verifyEmailToken"];

          if (registerResult["error_code"] == 109 && verifyToken != null) {

            let confirmAction = () => {

              showLoading();

              sendVerifyAccountEmailWithoutLogin(verifyToken).then(async responseResendVerifyEmail => {
                hideLoading();

                let verifyTitle = responseResendVerifyEmail.data["message"]["title"];
                let verifyMessage = responseResendVerifyEmail.data["message"]["message"];
                let verifyButton = responseResendVerifyEmail.data["message"]["button"];

                let okAction = () => {
                  backToLoginPage();
                }

                showAlertDialog(verifyTitle, verifyMessage, verifyButton, okAction);


              })
            };

            let okAction = () => {
              backToLoginPage();
            };

            showConfirmDialog(title, message, button, "OK", confirmAction, okAction);


          } else {
            // error ทั่วไปก็ show dialog ปุ่มเดียวปกติ
            if (message != null) {
              showAlertDialog(null, message, null);
            } else {
              showAlertDialog("Error", "Sign up error, please try again.", null);
            }
          }
        } else {
          showAlertDialog("Error", "Sign up error, please try again.", null);
        }
      }
    } else {
      showAlertDialog("Error", "Sign up error, please try again.", null);
    }

  }

  function showAlertDialog(title, message, button, okAction) {

    if (button == null) {
      button = "OK";
    }

    dispatch(dialogConfirmSetData({
      title: title,
      message: message,
      closeButton: null,
      confirmButton: button,
      eventConfirmButton: okAction,
      eventCloseButton: null,
      showCloseButton: false
    }));

    // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));
  }

  function showConfirmDialog(title, message, buttonTitle, closeButtonTitle, confirmAction, okAction) {
    dispatch(dialogConfirmSetData({
      title: title,
      message: message,
      closeButton: closeButtonTitle,
      confirmButton: buttonTitle,
      eventConfirmButton: confirmAction,
      eventCloseButton: okAction,
      showCloseButton: true
    }));

    // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));
  }

  function backToLoginPage() {
    navigate('/', { replace: true });
  }

  async function uploadCompanyCertPath(publisherId) {

    var uploadCoverImagePath = null;
    var isUploadCoverError = false;
    var uploadCoverErrorMessage = "";

    if (fileCompanyCert != '') {
      const uploadFilePromise = await uploadFileCompany(fileCompanyCert, publisherId)
        .then(async response => {
          // console.log(response.data);
          // console.log(response.data['error']);

          if (response.data['access_path'] != null && response.data['error'] != true) {
            uploadCoverImagePath = response.data['access_path'];
            await updateCompanyCertFilePathWithPublisherId(uploadCoverImagePath, publisherId);
          } else {
            isUploadCoverError = true;
            uploadCoverErrorMessage = "Upload company certificate error, please try again";
          }
        });

      // console.log("start image upload");
      await Promise.all([uploadFilePromise]);
      // console.log("end image upload");
    }

    if (isUploadCoverError) {
      let result = {
        "isUploadImageError": isUploadCoverError,
        "uploadImageErrorMessage": uploadCoverErrorMessage
      };
      return result;
    } else {

      let result = {
        "isUploadImageError": false,
        "uploadImageErrorMessage": ""
      };
      return result;
    }

  }


  return (

    <Container fluid>

      <HeadOnlyLanguageSelection />

      <Row className='signup-page'>
        <Col className='col-card' md={12}>
          <span className='text-signuptosell'>Sign up to sell books</span>
          <Card className='cardinfo' >
            <span className='inner-title-text'>Publisher / Writer Information </span>
            <hr className='hr-title' />
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group as={Row} className="mb-12" controlId="">

                <Form.Label column sm="3">
                  <span className='my-rowlabel'>Personal / Corporation </span>
                </Form.Label>
                <Col  >
                  <Form.Check
                    label="Personal"
                    type='radio'
                    name='personalCorporation'
                    value='1'
                    disabled
                    onChange={handleChangepersonalCorporation}
                    inline
                    className="orange-radio"

                  />
                  <Form.Check
                    label="Corporation"
                    type='radio'
                    name='personalCorporation'
                    value='2'
                    defaultChecked
                    onChange={handleChangepersonalCorporation}
                    inline
                    className="orange-radio"
                  />

                </Col>
              </Form.Group>



              {personalCorporation === '1' && (

                <div id="Personal">
                  <Form.Group as={Row} className="mb-3" controlId="validationCustom01">
                    <Form.Label column sm="3">
                      Pen Name / Publisher
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" placeholder="" value={formData.penNamePublisher} name="penNamePublisher" onChange={handleChange} />
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Name - Surname  <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" placeholder="" value={formData.nameSurname} name="nameSurname" onChange={handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      ID Card <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" pattern="[0-9]{13}" isInvalid={!!idCardError} value={formData.idCard} name="idCard" onChange={handleChange} />
                      <Form.Control.Feedback type="invalid">
                        {idCardError}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Phone Number <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="tel" maxLength={10} pattern="[0-9]{10}" isInvalid={!!phoneNumberError} placeholder="" value={formData.phoneNumber} name="phoneNumber" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                      <Form.Control.Feedback type="invalid">
                        {phoneNumberError}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
              )}



              {personalCorporation === '2' && (
                <div id="Corporation">

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Pen Name / Publisher
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control type="text" placeholder="" maxLength={60} defaultValue={formData.penNamePublisher} name="penNamePublisher" onChange={handleChange} />
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Corporation Name <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" placeholder="" maxLength={200} defaultValue={formData.CorporationName} name="CorporationName" onChange={handleChange} />
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Tax ID <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" maxLength={13} pattern="[0-9]{13}" isInvalid={!!taxIdError} defaultValue={formData.taxId} name="taxId" onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                      <Form.Control.Feedback type="invalid">
                        {taxIdError}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Name - Surname of The Coordinator <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" placeholder="" maxLength={200}  defaultValue={formData.nameSurname} name="nameSurname" onChange={handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Phone Number <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="tel" maxLength={10} pattern="[0-9]{10}" isInvalid={!!phoneNumberError} defaultValue={formData.phoneNumber} name="phoneNumber" onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                      <Form.Control.Feedback type="invalid">
                        {phoneNumberError}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Company certificate  <span className='star'>*</span>
                    </Form.Label>

                    <Col sm="8">
                      {(uploadCompany) ?
                        <div>
                          {selectedCompanyCertFileIsPDF == true ? (

                            <div>
                              <a className='normal-link link-button' onClick={handleClickPdf} >  {selectedCompanyCertFileName}    </a>
                            </div>
                          ) : (
                            <div>
                              <img src={uploadCompany} style={{ width: 250 }} />
                            </div>
                          )
                          }

                          <a onClick={() => deleteCompany()}
                            className='delete-link link-button'>
                            Delete
                          </a>
                        </div>
                        :
                        <div>
                          <Col sm="8">
                            <Form.Control ref={companyRef} style={{ width: '90%' }} required type="file" accept="image/jpeg,application/pdf" value={formData.uploadCompany} name="uploadCompany" onChange={handleImageChange} />
                          </Col>
                          <div>
                            <span className='text-alerts' >Supported file extensions .jpg .jpeg .pdf only</span>
                          </div>
                        </div>
                      }

                    </Col>
                  </Form.Group>





                  <br></br>
                  <span className='inner-title-text'>Accounting Department</span>
                  <hr className='hr-title' />

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Accounting Contact Name  <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" maxLength={60} placeholder="" value={formData.accountingContactName} name="accountingContactName" onChange={handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Accounting Phone Number  <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="tel" maxLength={10} pattern="[0-9]{10}" value={formData.accountingPhoneNumber} name="accountingPhoneNumber" onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Accounting Email  <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        maxLength={100}
                        required
                        type="email"
                        ref={accountEmailRef}
                        placeholder=""
                        value={formData.accountingEmail}
                        name="accountingEmail"
                        onChange={handleChange}
                      />

                    </Col>
                  </Form.Group>

                </div>
              )}





              <br></br>
              <span className='inner-title-text'> Address on ID Card </span>
              <hr className='hr-title' />
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Address <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required as="textarea" maxLength={300} rows={3} value={formData.address} name="address" onChange={handleChange} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Street <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required type="text" maxLength={100} placeholder="" value={formData.street} name="street" onChange={handleChange} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Subdistrict <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required type="text" maxLength={50}  placeholder="" value={formData.subdisTrict} name="subdisTrict" onChange={handleChange} />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  District <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required type="text" maxLength={50}  placeholder="" value={formData.disTrict} name="disTrict" onChange={handleChange} />
                </Col>
              </Form.Group>



              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Province <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Select required aria-label="Default select example" value={formData.province} name="province" onChange={handleChange}>
                    <option value=''>Please Select</option>
                    {optionsProvince.map((option) => (
                      <option key={option.province_id} value={option.province_id}>
                        {option.province_name}
                      </option>
                    ))}

                  </Form.Select>
                </Col>
              </Form.Group>


              <Form.Group as={Row} className="mb-3" controlId="" >
                <Form.Label column sm="3">
                  Zip code <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required type="text" maxLength={5} pattern="\d{1,5}" isInvalid={!!zipCodeError} value={formData.zipCode} onChange={handleChange} name="zipCode" onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                  <Form.Control.Feedback type="invalid">
                    {zipCodeError}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <br></br>
              <span className='inner-title-text'>Document Delivery Address</span>
              <hr className='hr-title' />
              <Form.Group as={Row} className="mb-12" controlId="">
                <Form.Label column sm="3">
                  <span className='my-rowlabel'>  Input Address </span> <span className='star'>*</span>
                </Form.Label>
                <Col >
                  <Form.Check
                    label="Input Address"
                    type='radio'
                    name='inputAddress'
                    value='1'
                    checked={selectedAddress === '1'}
                    onChange={handleChangeAddress}
                    inline
                  />

                  <Form.Check
                    label="Use The Address on The ID Card"
                    type='radio'
                    name='inputAddress'
                    value='2'
                    checked={selectedAddress === '2'}
                    onChange={handleChangeAddress}
                    inline
                  />
                </Col>
              </Form.Group>


              {selectedAddress === '1' && (
                <div>
                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Address <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required as="textarea" maxLength={300}  rows={3} value={formData.addressDocment} name="addressDocment" onChange={handleChange} />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Street <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" maxLength={100} placeholder="" value={formData.streetDocment} name="streetDocment" onChange={handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Subdistrict <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" maxLength={50} placeholder="" value={formData.subdistrictDocment} name="subdistrictDocment" onChange={handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      District <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" maxLength={50} placeholder="" value={formData.districtDocment} name="districtDocment" onChange={handleChange} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Province <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Select required aria-label="Default select example" value={formData.provinceDocment} name="provinceDocment" onChange={handleChange} >
                        <option value=''>Please Select</option>
                        {optionsProvince2.map((option) => (
                          <option key={option.province_id} value={option.province_id}>
                            {option.province_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>


                  <Form.Group as={Row} className="mb-3" controlId="">
                    <Form.Label column sm="3">
                      Zip code <span className='star'>*</span>
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control required type="text" maxLength={5} pattern="\d{1,5}" placeholder="" isInvalid={!!zipCodeDocmentError} value={formData.zipCodeDocment} name="zipCodeDocment" onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                      <Form.Control.Feedback type="invalid">
                        {zipCodeDocmentError}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </div>
              )}





              <br></br>
              <span className='inner-title-text'>Bank Account</span>
              <hr className="hr-title" />
              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Bank <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Select required aria-label="Default select example" value={formData.bank} name="bank" onChange={handleChange} >
                    <option value=''>Please Select</option>
                    {optionsBank.map((option) => (
                      <option key={option.bank_id} value={option.bank_id}>
                        {option.bank_name}
                      </option>
                    ))}

                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Account Name <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required type="text" maxLength={100} value={formData.accountName} name="accountName" onChange={handleChange} />
                  {/* <Form.Control.Feedback type="invalid">
                    {accountNumberError}
                  </Form.Control.Feedback> */}
                </Col>
              </Form.Group>


              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Account Number <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required type="text" maxLength={100} placeholder="" isInvalid={!!accountNumberError} value={formData.accountNumber} name="accountNumber" onChange={handleChange} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault() } }} />
                  <Form.Control.Feedback type="invalid">
                    {accountNumberError}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Account Type <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Select required aria-label="Default select example" value={formData.accountType} name="accountType" onChange={handleChange} >
                    <option value=''>Please Select</option>
                    {optionsgetBankAccount.map((option) => (
                      <option key={option.account_type_id} value={option.account_type_id}>
                        {option.account_type_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Branch <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Control required type="text" maxLength={100} placeholder="" value={formData.branch} name="branch" onChange={handleChange} />
                </Col>
              </Form.Group>


              <Form.Group as={Row} className="mb-3" controlId="">
                <Form.Label column sm="3">
                  Province <span className='star'>*</span>
                </Form.Label>
                <Col sm="8">
                  <Form.Select required aria-label="Default select example" value={formData.Province} name="Province" onChange={handleChange} >
                    <option value=''>Please Select</option>
                    {optionsProvince3.map((option) => (
                      <option key={option.province_id} value={option.province_id}>
                        {option.province_name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
              <br></br>
              <span className='inner-title-text'>Terms and Conditions of Use</span>
              <hr className='hr-title' />

              <Form.Group as={Row} className="mb-3" controlId="">
                <Col sm="3"></Col>
                <Col sm="8">
                By signing up, you agree to our <span className='abutton' onClick={()=>window.open('/term-of-use','_blank', 'rel=noopener noreferrer')} style={{cursor: 'pointer'}}> Terms of Use</span> and <span className='abutton' onClick={()=>window.open('https://www.flixerapp.com/privacy-policy','_blank', 'rel=noopener noreferrer')} style={{cursor: 'pointer'}}>Privacy Policy</span>
             
                <Form.Check
                      label="&nbsp;&nbsp;I hereby acknowledge that I have read, understood, and comprehensively agree to be bound by the terms and conditions of this agreement with Flixer Co., Ltd., by clicking on the 'Accept' button provided below."
                      className='check-signup'
                      onChange={handleChangeAccept}
                      value='1'
                      style={{marginTop: '0px', width: '100%', fontWeight: 'normal'}}
                    />
              
                </Col>
              </Form.Group>

              <br></br>
              <center>
                <div>
                  {showAlertError && (
                    <Alert variant="danger" onClose={() => setShowAlertError(false)}>
                      <p>
                        {showUploadImageErrorMessage}
                      </p>
                    </Alert>
                  )}
                </div>

                <Button className='button-signup button-main button-orange' variant="primary" 
                        type="submit"
                        disabled={!acceptAgreement}>Sign Up</Button>
                
              </center>
              <br></br>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <footer>Foodsdsdsdsdster</footer> */}
        </Col>
      </Row>

    </Container >

  );
}

export default SignupPage;
