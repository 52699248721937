import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { dialogConfirmSetShowModal, dialogSetOpenModal, dialogSetShowModal } from '../../actions/confirmDialog.action';

function ModalAlertDialog({showDialog , title, message}) {

    const showModal = useSelector(state => state.confirmDialog.showConfirmDialogModal);
    const confirmDialogData = useSelector(state => state.confirmDialog.confirmDialogData);
    const eventConfirmButton = useSelector((state) =>  state.confirmDialog.confirmDialogData.eventConfirmButton);
    const eventCloseButton = useSelector((state) =>  state.confirmDialog.confirmDialogData.eventCloseButton);
    // const closeModal = useSelector(state => state.confirmDialog.closeModal);

    const [show, setShow] = useState(showDialog);


    const dispatch = useDispatch();

    const closeModal = () => {
        if (eventCloseButton != null) {
            eventCloseButton();
        }
        setShow(false);
        dispatch(dialogConfirmSetShowModal(false));
    }

    const handleClick = () => {
        if (eventConfirmButton != null) {
            eventConfirmButton();
        }
        setShow(false);
        dispatch(dialogConfirmSetShowModal(false));
    }

    useEffect(() => {
        if (showModal == true) {
            setShow(showModal)
        }
    }, [showModal])

  return (
    // <Modal show={show} onHide={closeModal}>
    <Modal show={show} >
        <Modal.Header>
            <Modal.Title>{confirmDialogData.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {/* <p>Body Content.</p> */}
            {confirmDialogData.message}
        </Modal.Body>

        <Modal.Footer>
            {(confirmDialogData.showCloseButton == true) ?
            <Button variant="secondary" onClick={closeModal}>{confirmDialogData.closeButton}</Button>
            : ''}
            <Button onClick={() => handleClick()} variant="warning" style={{ border: '0px',backgroundColor: 'var(--color-main-theme)', color: 'white' }}>{confirmDialogData.confirmButton}</Button>
        </Modal.Footer>
    </Modal>
  )
}

export default ModalAlertDialog

        // ------- ตัวอย่างการเรียกใช้งาน Confirm Dialog Data ------
 
        //-------** type ของตัวแปรต่างๆ **------
        //title = string
        //message = string
        //closeButton = string
        //confirmButton = string
        //eventConfirmButton = ใส่ฟังก์ชัน ถ้าไม่มี ใส่ null
        //eventCloseButton = ใส่ฟังก์ชัน ถ้าไม่มี ใส่ null
        //showCloseButton = boolean (ให้โชว์ปุ่ม close หรือ cancel หรือเปล่า)
        // -------** ------- **------

        // ** set data ท่ีจะทำการโชว์ dialog ตามตัวอย่างด้านล่าง **
        // ****************************************************************

        // dispatch(dialogConfirmSetData({
        //     title: "Delete Item",
        //     message: "Are you sure you want to delete this item?",
        //     closeButton: "Cancel",
        //     confirmButton: "Delete",
        //     eventConfirmButton: () => console.log("Item deleted"),
        //     eventCloseButton: () => handleClickPdf(0),
        //     showCloseButton: false
        // }));
        // // ** ทำให้ dialog โชว์ขึ้นมา **
        // dispatch(dialogConfirmSetShowModal(true));

        // ****************************************************************
