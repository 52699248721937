import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../image/logo_main.png'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../helper/ApiHelper.js'
import { emailSignUp } from '../../api'
import Alert from 'react-bootstrap/Alert';
import { useDispatch } from 'react-redux';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';


function SignUp() {
  const [showError, setShowError] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState("Password not match");
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmpassword: ''
   });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(!regEmail.test(formData.email)){
      // return 'Invalid Email';
      dispatch(dialogConfirmSetData({
        title: "Error",
        message: "Invalid Email.",
        closeButton: "",
        confirmButton: "OK",
        eventConfirmButton: () => null,
        eventCloseButton: () => null,
        showCloseButton: false
    }));
    // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));
    } else {
      if (formData.password !== formData.confirmpassword) {
        // setShowError(true);
        // setDisplayErrorMessage("Password not match");
        dispatch(dialogConfirmSetData({
          title: "Error",
          message: "Password not match.",
          closeButton: "",
          confirmButton: "OK",
          eventConfirmButton: () => null,
          eventCloseButton: () => null,
          showCloseButton: false
      }));
      // ** ทำให้ dialog โชว์ขึ้นมา **
      dispatch(dialogConfirmSetShowModal(true));
      } else {
        emailSignUp(formData.email, formData.password).then(handleResponse)
      }
    }
  }

  const handleResponse = async (response) => {
    // console.log(response.data['error']);

    if (response.data['error'] === true) {
      setShowError(true);
      let errorMessageObject = response.data["message"];
      let errorMessage = errorMessageObject["message"];

      setDisplayErrorMessage(errorMessage);
    } else {
      setShowError(false);
      navigate('/SignUpInfo-Personal', {
        state: {
          email: formData.email,
          password: formData.password
        },
      });
    }
  }


  return (

    <div>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <div className="container-fluid">
        <div className="container" style={{ width: '500px', marginTop: '80px' }}>
          <div className="row">
            <div className='col-md-12 col-sm-12 col-xs-12 login-content'>
              <center >
                <img src={logo} className="App-logo" alt="logo" width="60%" style={{ marginBottom: '70px' }} />

                <h3> <b style={{ fontSize: 30 }} > SIGN UP</b></h3>
              </center>
              <br></br>
              <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <input type="email" value={formData.email} placeholder="Email" name="email" onChange={handleChange} className='form-control' required />
                </div>

                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <input type="password" value={formData.password} placeholder="Password" name="password" onChange={handleChange} className='form-control' required />
                </div>

                <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                  <input type="password" value={formData.confirmpassword} placeholder="Confirm Password" name="confirmpassword" onChange={handleChange} className='form-control' required />
                </div>

                {showError && (
                  <Alert variant="danger">
                    <center>
                      {displayErrorMessage}
                    </center>
                  </Alert>
                )}

                <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                  <button type="submit" className='button-bold-text'>SIGN UP</button>
                </div>

                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    <span className="login-option">Have an account?</span>
                    <Link to="/" className="login-option-button">Sign In</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default SignUp;