
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import StatusEbooks from '../../components/StatusEbooks/index.js';
import NavBar from '../../components/navbar/NavBar.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Modal from 'react-bootstrap/Modal';
import Icon_PDF from '../../image/PDF.png';
import Icon_History from '../../image/History.png';
import Icon_Reject from '../../image/Reject.png';
import Icon_View from '../../image/View.png';
import { pdfjs ,Document , Page  } from 'react-pdf';
import { Suspense, lazy } from 'react';
import {convertToNumberWith2Decimal, getCurrencyDisplay,localeString, STATUS_READY_FOR_SALE, getHistoryEbook, STATUS_REMOVED_FROM_SALE, STATUS_PUBLISHER_REMOVE_FROM_SALE, limitPageEbookDetails, STATUS_IN_REVIEW, STATUS_WAITING_FOR_REVIEW, getAllEbookDetails, apiSearchEbookDetails, getApproveStatus, updateEbookApproveStatusDetails, listEbookSearchOptions, STATUS_REJECTED, STATUS_PENDING_RELEASE, getEbookURL } from '../../api/index.js'
import accessPath from '../../api/index.js';
import { useNavigate , useLocation } from 'react-router-dom';
import { hideLoading, showLoading } from '../../helper/Helper.js';
import { useDispatch, useSelector } from 'react-redux';
import {  setCurrentBooksApprovalInProgressPage , setCurrentBooksApprovalReadyofSalePage , setBooksApprovalSortOption} from '../../actions/site.action.js'; 
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder/index.js';
import placeholderImg from '../../image/placeHolder_cover.png'; 


const BooksApproval = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const currentBooksApprovalInProgressPage = useSelector(state => state.site.currentBooksApprovalInProgressPage);
    const currentBooksApprovalReadyofSalePage = useSelector(state => state.site.currentBooksApprovalReadyofSalePage);
    const { locationActiveTabKey , searchKeyword , searchOption  } = location.state || {};
    const navigate = useNavigate();
    const [activeTabKey, setActiveTabKey] = useState(locationActiveTabKey);
    const [approveStatus, setApproveStatus] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [formData, setFormData] = useState('');
    const [historyEbookData, sethistoryEbook] = useState([]);
    const [ebookId, setEbookId] = useState('');
    const notes = useRef();
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [shouldShowReviewPDFModal, setShouldShowReviewPDFModal] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [pageInProgress, setPageInProgress] = useState(currentBooksApprovalInProgressPage); 
    const [datagetEbookDetailsInProgress, setEbookDetailsInProgress] = useState([]);
    const [countEbookDetailsInProgress, setCountEbookDetailsInProgress] = useState([]);
    const [pageReadyofSale, setPageReadyofSale] = useState(currentBooksApprovalReadyofSalePage);
    const [dataGetEbookDetailsReadyForSale, setEbookDetailsReadyForSale] = useState([]);
    const [countEbookDetailsReadyForSale, setCountEbookDetailsReadyForSale] = useState([]);
    const [numPages, setNumPages] = useState(null);
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfPassword, setPdfPassword] = useState('');
    const LazyPage = lazy(() => import('react-pdf').then(module => ({ default: module.Page })));

    const booksApprovalSortOption = useSelector(state => state.site.booksApprovalSortOption);
    const [sortColumn, setSortColumn] = useState(booksApprovalSortOption);
    const getIcon = (columnKey) => {
        const sortItem = sortColumn.find(item => item.columnKey === columnKey);
        if (sortItem?.direction === 'ascending') {
          return <FontAwesomeIcon icon={faSortUp} />;
        }
        if (sortItem?.direction === 'descending') {
          return <FontAwesomeIcon icon={faSortDown} />;
        }
        return <FontAwesomeIcon icon={faSort} />;
      };
    
      const requestSort = (columnKey) => {
        setPageInProgress(1); 
        setPageReadyofSale(1);
        let newSortConfig = [...sortColumn];
        const existingSortItemIndex = newSortConfig.findIndex(item => item.columnKey === columnKey);
    
        if (existingSortItemIndex !== -1) {
          if (newSortConfig[existingSortItemIndex].direction === 'ascending') {
            newSortConfig[existingSortItemIndex].direction = 'descending';
          } else if (newSortConfig[existingSortItemIndex].direction === 'descending') {
            newSortConfig.splice(existingSortItemIndex, 1);  // Remove the sort item if it's already descending
          }
        } else {
          newSortConfig.push({ columnKey: columnKey, direction: 'ascending' });
        }
        // console.log(newSortConfig);
    
        setSortColumn(newSortConfig);
        showEbookDetailsInProgress(1 ,newSortConfig );
        showEbookDetailsReadyofSale(1 ,newSortConfig )
        dispatch(setBooksApprovalSortOption(newSortConfig));
      
      };

    const [keyword, setKeyword] = useState(searchKeyword || '');
    const refKeywordEbook = useRef();
    useEffect(() => {
        if (refKeywordEbook.current) {
          setKeyword(refKeywordEbook.current.value || '');
        }
      }, [refKeywordEbook , keyword]);




    const modalRejectClose = () => setShowRejectModal(false);
    const modalRejectShow = () => setShowRejectModal(true);

    const uppercaseFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const handleSearch = () => {
        const searchKeyword = formData.keyword !== undefined ? formData.keyword : keyword;
        const getSearchOption = getOption();
        setKeyword(searchKeyword, getSearchOption);
        searchEbookDetailsInProgress(searchKeyword, getSearchOption);
        searchEbookDetailsReadyofSale(searchKeyword, getSearchOption);
    }



    //Show InProgress 
    function showEbookDetailsInProgress(page = '1' , newSortConfig) {
        //const value = formData.searchOption || listEbookSearchOptions[0]['value'];
        const getSearchOption = getOption();

        if (keyword != "") {
            searchEbookDetailsInProgress(keyword, getSearchOption , page);
        } else {
            showLoading();
            getAllEbookDetails(page, limitPageEbookDetails ,newSortConfig).then(response => {
                setEbookDetailsInProgress(response.data['booksInProgress']);
                setCountEbookDetailsInProgress(response.data['countEbooksInProgress'][0]['all_books']);
                hideLoading();  
            });
        }
        
    }


    function searchEbookDetailsInProgress(keyword, searchOption, page = '1') {
        showLoading();
        apiSearchEbookDetails(keyword, searchOption, page, limitPageEbookDetails ,sortColumn).then(response => {
            console.log(response.data);
            setEbookDetailsInProgress(response.data['booksInProgress']);
            setCountEbookDetailsInProgress(response.data['countEbooksInProgress'][0]['all_books']);
            hideLoading();  
        });
    }
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //Show Ready ofor Sale
    function showEbookDetailsReadyofSale(page = '1' , newSortConfig) {
       // const value = formData.searchOption || listEbookSearchOptions[0]['value'];
       const getSearchOption = getOption();
        if (keyword != "") {
            searchEbookDetailsReadyofSale(keyword,getSearchOption, page);
        } else {
            showLoading();
            getAllEbookDetails(page, limitPageEbookDetails ,newSortConfig).then(response => {
                setEbookDetailsReadyForSale(response.data['booksReadyForSale']);
                //console.log(response.data);
                setCountEbookDetailsReadyForSale(response.data['countEbooksReadyForSale'][0]['all_books']);
                hideLoading();  
            });
        }
    }

   
    function searchEbookDetailsReadyofSale(keyword, searchOption, page = '1') {
        showLoading();
        apiSearchEbookDetails(keyword, searchOption, page, limitPageEbookDetails ,sortColumn).then(response => {
            setEbookDetailsReadyForSale(response.data['booksReadyForSale']);
            setCountEbookDetailsReadyForSale(response.data['countEbooksReadyForSale'][0]['all_books']);
            hideLoading();  
        });
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    

    function getOption(){
        const getSearchOption = formData.searchOption || searchOption || listEbookSearchOptions[0]['value'];
        return getSearchOption;
    }


    function historyEbook(ebookId) {
        showLoading();
        sethistoryEbook([]);
        getHistoryEbook(ebookId).then(response => {
            sethistoryEbook(response.data);
            hideLoading();
        });
    }

    function showApproveStatus() {
        getApproveStatus().then(response => {
            setApproveStatus(response.data);
        });
    }

    const handleReject = () => {
        showLoading();
        const valueNotes = notes.current.value;
        updateEbookApproveStatusDetails(ebookId, STATUS_REMOVED_FROM_SALE, valueNotes).then(response => {
            hideLoading();
            window.location.reload();
        });
    }

    const handleViewEbooks = (ebookDetailsID, bookTitle) => {
        const getSearchOption = getOption();
        navigate('/ViewEbooksAdmin', {
            state: {
                ebookDetailsID: ebookDetailsID,
                bookTitle: bookTitle ,
                activeTabKey: activeTabKey , 
                searchKeyword : keyword ,
                searchOption : getSearchOption
            },
        });
    };

    function getOption(){
        const getSearchOption = formData.searchOption || searchOption || listEbookSearchOptions[0]['value'];
        return getSearchOption;
    }


    const handleChangeStatus = (ebookId, approveStatusId, event) => {

        const { name, value } = event.target || '';

        let textrejected = null;
        let newStatusId = null;
        if (name != 'textrejected') {
            newStatusId = event.target.value;

            // ตรวจว่า ถ้าเลือกสถานะเป็น Rejected จะแสดงให้กรอกข้อความเพื่อ Rejected
            if (newStatusId == STATUS_REJECTED) {
                if (selectedIds.includes(ebookId) == false) {
                    setSelectedIds([...selectedIds, ebookId]);
                }
            } else {

                setSelectedIds(selectedIds.filter(selectedId => selectedId !== ebookId));
            }
        }

        // ตรวจสอบว่ามี ebookId นี้อยู่ในอาร์เรย์ selectedOptions หรือไม่
        const index = selectedOptions.findIndex((option) => option.ebookId === ebookId);
        if (index !== -1) {
            // หากมี ebookId นี้อยู่ในอาร์เรย์ selectedOptions อัปเดตค่า idapprove ของอินสเตนซ์นั้น
            const updatedOptions = [...selectedOptions];

            if (name == 'textrejected') {
                textrejected = event.target.value;
                updatedOptions[index].textrejected = textrejected;
            } else {
                newStatusId = event.target.value;
                updatedOptions[index].idapprove = newStatusId;
                updatedOptions[index].textrejected = null;
            }


            setSelectedOptions(updatedOptions);
        } else {
            // หาก ebookId นี้ยังไม่มีอยู่ในอาร์เรย์ selectedOptions เพิ่มอินสเตนซ์ใหม่
            const newOption = {
                ebookId,
                idapprove: newStatusId,
                textrejected: textrejected
            };
            setSelectedOptions([...selectedOptions, newOption]);
        }

        // console.log(selectedOptions);
    };

    const searchMosName = (ebookId) => {
        const foundItem = selectedOptions.find((item) => item.ebookId === ebookId);
        return foundItem ? foundItem.idapprove : null;
    };

    const handleSubmit = () => {
        showLoading();
        updateAllOptionsAndReload();
    }

    async function updateAllOptionsAndReload() {
        try {
            // อัปเดตทุกตัวเลือกและรอให้ทุกอย่างเสร็จสิ้นก่อนที่จะดำเนินการต่อ
            await updateAllOptions();
            hideLoading();
            // รีโหลดหน้าเพจ
            window.location.reload();
        } catch (error) {

        }
    }

    async function updateAllOptions() {
        // สร้าง promises เพื่ออัปเดตข้อมูลแต่ละตัวที่เลือก
        const updatePromises = selectedOptions.map(async (option) => {
            const response = await updateEbookApproveStatusDetails(option.ebookId, option.idapprove, option.textrejected);
            return response.data;
        });
        // รอให้ทุก promise เสร็จสิ้นก่อนที่จะดำเนินการต่อ
        await Promise.all(updatePromises);
    }

    const handleSelect = (key) => {
        setActiveTabKey(key);
        setSortColumn([]);
    };

    function showReviewPDFModal(ebookId){
        getEbookURL(ebookId).then(response => {
            if(response.data.error == false){
                var responsePdfUrl = response.data.result.access_url;
                var bookPassword = response.data.result.book_password;
                
                setPdfPassword(bookPassword);
                setPdfUrl(responsePdfUrl);
                
            }
            setShouldShowReviewPDFModal(true);
        });
    }
       
    function trimFirstAndLastTenChars(str) {
        if (str.length <= 20) return ''; // Return empty string if string is too short
        return str.substring(10, str.length - 10);
    }

    const handleChange = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        // setKeyword(value);     console.log(keyword);
        // searchEbookDetails(value);

        if (event.key === 'Enter') {
            setSortColumn([]);
            handleSearch();
            setPageInProgress('1');
            setPageReadyofSale('1');
        }

    }

    useEffect(() => {
        showEbookDetailsInProgress(currentBooksApprovalInProgressPage , sortColumn);
        showEbookDetailsReadyofSale(currentBooksApprovalReadyofSalePage , sortColumn);
        showApproveStatus();
    }, []);



    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
      
    const handlePassword = (callback) => {
        var password = pdfPassword;
        var bookPassword = trimFirstAndLastTenChars(password);
        callback(bookPassword);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };


    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>
                        <div className='box-context'>
                            <div className='div-row-col'>
                                <Row sm={12}>
                                <span className='title-text'>Books Approval</span>
                                    <p className='context-text'>Manage all book status.</p>
                                   
                                    <Col sm={12} md={5}></Col>
                                    <Col sm={7}>
                                        <InputGroup>
                                            <Form.Control style={{ width: '200px' }} ref={refKeywordEbook} defaultValue={keyword} className='form-control-custom' name='keyword'
                                             onKeyUp={(e) => {
                                                handleChange(e);
            
                                            }} placeholder="Search..." aria-label="Search..." />
                                            <Form.Select name='searchOption' onChange={handleChange} defaultValue={searchOption} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} >
                                                {Object.keys(listEbookSearchOptions).map((key) => (
                                                    <option value={listEbookSearchOptions[key].value} key={key}>
                                                        {listEbookSearchOptions[key].name}
                                                    </option>
                                                ))}
                                            </Form.Select>

                                            <div style={{ marginLeft: '10px' }}>
                                                <Col>
                                                    <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                        handleSearch();
                                                        setSortColumn([]);
                                                        setPageInProgress('1');
                                                        setPageReadyofSale('1');
                                                    }} >Search</Button>
                                                </Col>
                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </div>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>

                                        <Tabs id="fill-tab-example"
                                            className="tabs-box"
                                            activeKey={activeTabKey}
                                            onSelect={handleSelect}
                                            fill>
                                            <Tab className='tab-text' eventKey="inProgress" title="In Progress">
                                                <Col className='' >
                                                    <main>
                                                        <div className=''>
                                                            <div className=''>

                                                                <Table width={'100%'} style={{ overflowX: 'auto', overflowY: 'auto', marginTop: '15px' }} >
                                                                    <thead className='thead-text'>
                                                                        <tr>
                                                                            <th width={'30%'} onClick={() => requestSort('bookTitle')}   >  <p className='th-Text'>Book Title   <span>{getIcon('bookTitle')}</span> </p></th>
                                                                            <th width={'10%'} onClick={() => requestSort('authorName')}  >  <p className='th-Text'>Author Name  <span>{getIcon('authorName')}</span> </p></th>
                                                                            <th width={'10%'} onClick={() => requestSort('category')}    >  <p className='th-Text'>Category     <span>{getIcon('category')}</span></p></th>
                                                                            <th width={'30%'}  ><p>Change Status </p></th>
                                                                            <th width={'20%'}><p>Action</p></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {Array.isArray(datagetEbookDetailsInProgress) ? (
                                                                            datagetEbookDetailsInProgress.map((option, index) => {
                                                                                var isPriceNotReady = option.ios_review_status != "ready" || option.android_review_status != "ready";
                                                                                
                                                                                if (option.approve_status_id == STATUS_WAITING_FOR_REVIEW || option.approve_status_id == STATUS_IN_REVIEW || option.approve_status_id == STATUS_REJECTED) {
                                                                                    return (
                                                                                        <tr key={option.ebook_id}>
                                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                            <div className='image-as-title' >
                                                                                                <ImageWithPlaceholder
                                                                                                        src={accessPath + option.cover_image_path}
                                                                                                        placeholder={placeholderImg}
                                                                                                        className = 'book-overview-page'
                                                                                                    />
                                                                                                <p className='td-text image-as-title'>
                                                                                                    
                                                                                                   
                                                                                                    <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>
                                                                                                        {option.book_title}
                                                                                                    </span>
                                                                                                </p>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td style={{ verticalAlign: 'middle' }}>  <p className='td-text'>{option.book_author} </p> </td>
                                                                                            <td style={{ verticalAlign: 'middle' }}>  <p className='td-text'> {option.name_en} </p> </td>
                                                                                            <td style={{ verticalAlign: 'middle', paddingTop: '18px', paddingBottom: '18px' }}>

                                                                                                <Form.Select
                                                                                                    disabled={isPriceNotReady && option.sale_type != "free" ? true : false}
                                                                                                    value={searchMosName(option.ebook_id) || option.approve_status_id}
                                                                                                    onChange={(event) => handleChangeStatus(option.ebook_id, option.approve_status_id, event)}>
                                                                                                    {approveStatus.map((option) => (
                                                                                                        
                                                                                                        <option value={option.approve_status_id} key={option.approve_status_id}>
                                                                                                            {option.status_name}
                                                                                                            {option.ebook_id}
                                                                                                        </option>

                                                                                                    ))}
                                                                                                    <option value='approve'> Approve</option>
                                                                                                </Form.Select>
                                                                                                <div style={{ paddingTop: '10px' }} >

                                                                                                    <div key={option.ebook_id} style={{ display: selectedIds.includes(option.ebook_id) ? 'block' : 'none' }}>
                                                                                                        <span className='td-textChangeStatus'>
                                                                                                            <div className='col-md-12'>
                                                                                                                <font style={{ float: 'left' }}>Reason :</font>

                                                                                                                <textarea name="textrejected"
                                                                                                                    onChange={(event) => handleChangeStatus(option.ebook_id, option.approve_status_id, event)}
                                                                                                                    placeholder='Reason to reject?' rows="2" cols="50" style={{ width: '100%', borderRadius: '8px', padding: '10px', color: 'black' }}>

                                                                                                                </textarea>
                                                                                                            </div>
                                                                                                        </span>

                                                                                                    </div>

                                                                                                    <span className='td-textChangeStatus'>
                                                                                                        <div className='col-md-12'>
                                                                                                            <font style={{ float: 'left' }}>Price :</font>
                                                                                                            {isPriceNotReady && option.sale_type != "free" ? (
                                                                                                                <font style={{ float: 'right', color: 'red' }}>{option.sale_type === 'for_sale' ? getCurrencyDisplay(convertToNumberWith2Decimal(option.price)) : (option.sale_type ? uppercaseFirstLetter(option.sale_type) : 'Free')}</font>
                                                                                                            ) : (
                                                                                                                <font style={{ float: 'right', color: '#4A4C51' }}>{option.sale_type === 'for_sale' ? getCurrencyDisplay(convertToNumberWith2Decimal(option.price)) : (option.sale_type ? uppercaseFirstLetter(option.sale_type) : 'Free')}</font>
                                                                                                            )}

                                                                                                            {isPriceNotReady && option.sale_type != "free" ? (

                                                                                                                // Display book title if it exists and is truthy
                                                                                                                <label className="label-alert-inapp-status">
                                                                                                                    This book price is not yet available. Pending approval from Apple.
                                                                                                                </label>
                                                                                                            ) : ("")}

                                                                                                        </div>
                                                                                                    </span>

                                                                                                    <span className='td-textChangeStatus' >
                                                                                                        <div className='col-md-12'>
                                                                                                            <font style={{ float: 'left' }}>Publish Date:</font>
                                                                                                            <font style={{ float: 'right', color: '#4A4C51' }}>{option.publish_options === 'as_review_pass' ? 'As soon as review pass' : (localeString(option.publish_date) || 'As soon as review pass')} </font>
                                                                                                        </div>
                                                                                                    </span>
                                                                                                </div>
                                                                                            </td>

                                                                                            <td style={{ verticalAlign: 'middle' }}>
                                                                                                <div style={{ marginBottom: '6px' }}>
                                                                                                    <a onClick={() => { showReviewPDFModal(option.ebook_id) }}
                                                                                                        className='normal-link link-button'>
                                                                                                        <img src={Icon_PDF} style={{ width: '20px' }}></img>  View PDF</a>
                                                                                                </div>

                                                                                                <div style={{ marginBottom: '6px' }}>
                                                                                                    <a onClick={() => handleViewEbooks(option.ebook_id, option.book_title)}
                                                                                                        className='normal-link link-button'>
                                                                                                        <img src={Icon_View} style={{ width: '20px' }}></img>  View Info
                                                                                                    </a>
                                                                                                </div>
                                                                                                <div style={{ marginBottom: '6px' }}>
                                                                                                    <a onClick={() => {
                                                                                                            setShowHistoryModal(true);
                                                                                                            historyEbook(option.ebook_id);
                                                                                                        }}
                                                                                                        className='normal-link link-button'>
                                                                                                        <img src={Icon_History} style={{ width: '20px' }}></img> History
                                                                                                    </a>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            })
                                                                        ) : (
                                                                            <Form.Label type="text">
                                                                                {datagetEbookDetailsInProgress && datagetEbookDetailsInProgress['book_title'] ? datagetEbookDetailsInProgress['book_title'] : ""}
                                                                            </Form.Label>
                                                                        )}
                                                                        

                                                                    </tbody>
                                                                </Table>
                                                                        {(datagetEbookDetailsInProgress.length < 1 && keyword !== '') ? 
                                                                            <div className='search-no-result'><center><strong>No results for '{keyword}'</strong></center></div>: ''
                                                                        }
                                                            </div>
                                                        </div>

                                                    </main>
                                                </Col>

                                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <PaginationControl
                                                        page={pageInProgress}
                                                        between={4}
                                                        total={countEbookDetailsInProgress}
                                                        limit={limitPageEbookDetails}
                                                        changePage={(page) => {
                                                            setPageInProgress(page);
                                                            showEbookDetailsInProgress(page , sortColumn);
                                                            dispatch(setCurrentBooksApprovalInProgressPage(page))
                                                        }}
                                                        ellipsis={10}
                                                    />
                                                </div>

                                                {datagetEbookDetailsInProgress && datagetEbookDetailsInProgress.length > 0 ? (
                                                    // Display book title if it exists and is truthy
                                                    <center>
                                                        <Button className='button-form-save button-main button-orange' onClick={handleSubmit}>Save</Button>
                                                    </center>
                                                ) : (
                                                    // Display the button if datagetEbookDetails is an empty array or if there is no book title
                                                    ''
                                                )}
                                            </Tab>

                                            <Tab eventKey="readyForSale" title="Ready for Sale">

                                                <Col >
                                                    <main>
                                                        <div>
                                                            <Table style={{ overflowX: 'auto', overflowY: 'auto', marginTop: '15px' }}>
                                                                <thead className='thead-text'>
                                                                    <tr>
                                                                        <th width={'30%'} onClick={() => requestSort('bookTitle')}   >  <p className='th-Text'>Book Title   <span>{getIcon('bookTitle')}</span> </p></th>
                                                                        <th width={'10%'} onClick={() => requestSort('authorName')}  >  <p className='th-Text'>Author Name  <span>{getIcon('authorName')}</span> </p></th>
                                                                        <th width={'10%'} onClick={() => requestSort('category')}    >  <p className='th-Text'>Category     <span>{getIcon('category')}</span></p></th>
                                                                        <th width={'25%'} onClick={() => requestSort('status')}      >  <p className='th-Text'>Status       <span>{getIcon('status')}</span></p></th>
                                                                        <th width={'20%'}><p>Action</p></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {Array.isArray(dataGetEbookDetailsReadyForSale) ? (
                                                                        dataGetEbookDetailsReadyForSale.map((option, index) => {

                                                                            if (option.approve_status_id == STATUS_READY_FOR_SALE || option.approve_status_id == STATUS_REMOVED_FROM_SALE || option.approve_status_id == STATUS_PUBLISHER_REMOVE_FROM_SALE || option.approve_status_id == STATUS_PENDING_RELEASE) {

                                                                                return (
                                                                                    <tr key={option.ebook_id}>
                                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                                            <div  className='image-as-title' >
                                                                                            <ImageWithPlaceholder
                                                                                                        src={accessPath + option.cover_image_path}
                                                                                                        placeholder={placeholderImg}
                                                                                                        className = 'book-overview-page'
                                                                                                />
                                                                                             <p className='td-text image-as-title'>
                                                                                                
                                                                                                <span style={{ paddingLeft: '10px', paddingTop: '1px' }}>
                                                                                                    {option.book_title}
                                                                                                </span>
                                                                                            </p>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td style={{ verticalAlign: 'middle' }}>  <p className='td-text'>{option.book_author} </p> </td>
                                                                                        <td style={{ verticalAlign: 'middle' }}>  <p className='td-text'> {option.name_en} </p> </td>


                                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                                            <span className='td-text image-as-title' style={{ textAlign: 'top' }}>
                                                                                                <StatusEbooks status={option.approve_status_id} />
                                                                                                {option.status_name}

                                                                                            </span>
                                                                                        </td>
                                                                                        

                                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                                            
                                                                                            <div style={{ marginBottom: '6px' }}>
                                                                                                <a onClick={() => handleViewEbooks(option.ebook_id, option.book_title)}
                                                                                                    className='normal-link link-button'>
                                                                                                    <img src={Icon_View} style={{ width: '20px' }}></img>  View Info
                                                                                                </a>
                                                                                            </div>
                                                                                            <div style={{ marginBottom: '6px' }}>
                                                                                                <a onClick={() => {
                                                                                                        setShowHistoryModal(true);
                                                                                                        historyEbook(option.ebook_id);
                                                                                                    }}
                                                                                                    className='normal-link link-button'>

                                                                                                    <img src={Icon_History} style={{ width: '20px' }}></img> History
                                                                                                </a>
                                                                                            </div>
                                                                                            <div style={{ marginBottom: '6px' }}>
                                                                                                <a value="reject"
                                                                                                    onClick={() => {
                                                                                                        setEbookId(option.ebook_id);
                                                                                                        modalRejectShow();
                                                                                                    }}

                                                                                                    className='normal-link link-button'>
                                                                                                    <img src={Icon_Reject} style={{ width: '20px' }}></img> Reject
                                                                                                </a>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                        })
                                                                    ) : (
                                                                        <Form.Label type="text">
                                                                            {dataGetEbookDetailsReadyForSale && dataGetEbookDetailsReadyForSale['book_title'] ? dataGetEbookDetailsReadyForSale['book_title'] : ""}
                                                                        </Form.Label>
                                                                    )}
                                                                </tbody>
                                                                
                                                            </Table>
                                                        </div>
                                                    </main>
                                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                        <PaginationControl
                                                            page={pageReadyofSale}
                                                            between={4}
                                                            total={countEbookDetailsReadyForSale}
                                                            limit={limitPageEbookDetails}
                                                            changePage={(page) => {
                                                                //console.log(page);
                                                                setPageReadyofSale(page);
                                                                showEbookDetailsReadyofSale(page , sortColumn);
                                                                dispatch(setCurrentBooksApprovalReadyofSalePage(page))
                                                            }}
                                                            ellipsis={10}
                                                        />
                                                    </div>
                                                </Col>

                                            </Tab>
                                        </Tabs>
                                    </Card.Body>


                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>
            </Row >

            <Modal size='xl'
                    show={shouldShowReviewPDFModal}
                    onHide={() => setShouldShowReviewPDFModal(false)}
                    aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="pdf-container">
                    <Suspense fallback={<div>Loading...</div>}>
                        <Document
                            file={pdfUrl}
                            onPassword={handlePassword}
                            onLoadSuccess={onDocumentLoadSuccess}
                            renderForms={false}
                            className='pdf-show'
                            options={{
                                withCredentials:true
                            }}
                        >
                            {Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map((page) => {
                                    return (
                                        
                                        <LazyPage
                                            width={600}
                                            key={`page_${page}`}
                                            pageNumber={page}
                                            renderForms={false}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                        
                                    );
                                })}
                        </Document>
                        </Suspense>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal size="lg"
                show={showHistoryModal}
                onHide={() => setShowHistoryModal(false)}
                aria-labelledby="example-modal-sizes-title-lg">
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <Table >
                        <thead className='thead-text'>
                            <tr>
                                <th>Activity</th>
                                <th>Rejected Reason</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(datagetEbookDetailsInProgress) ? (
                                historyEbookData.map((option, index) => (
                                    <tr key={option.log_id}>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{option.status_name}</td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}>{option.notes}</td>
                                        <td style={{ verticalAlign: 'middle', textAlign: 'center' }}> {localeString(option.status_update_date)}</td>
                                    </tr>
                                ))
                            ) : (
                                <td style={{ verticalAlign: 'middle' }}></td>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>

            {/* modalReject */}
            <Modal show={showRejectModal} onHide={modalRejectClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reason to reject?</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <textarea id="" name="notes" ref={notes} placeholder='Reason to reject?' rows="4" cols="50" style={{ width: '100%', borderRadius: '8px', padding: '10px' }}>

                    </textarea>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={modalRejectClose}>Cancel</Button>
                    <Button onClick={() => handleReject()} variant="danger" style={{ border: '0px' }}>Reject</Button>
                </Modal.Footer>
            </Modal>

        </div >
    );
};

export default BooksApproval;
