export const dialogConfirmSetShowModal = showConfirmDialogModal => ({
    type: 'SHOW_CONFIRM_DIALOG_MODAL',
    showConfirmDialogModal
})

export const dialogConfirmSetData = confirmDialogData => ({
    type: 'CONFIRM_DIALOG_DATA',
    confirmDialogData
})


