const initialState = {
    showLoadingStatus: false,
    currentManageBookPage: 1,
    currentBooksApprovalInProgressPage: 1 ,
    currentBooksApprovalReadyofSalePage: 1,
    manageBookSortOption: [] , 
    booksApprovalSortOption: [] ,
    manageReviewSortOption: []
    // currentScrollY: 0
    
}

const site = (state = initialState, action) => {
    switch (action.type) {
        case 'SITE_SET_SHOW_LOADING_STATUS':
            return { ...state, showLoadingStatus: action.showLoadingStatus }
        case 'CURRENT_MANAGE_BOOK_PAGE':
                return { ...state, currentManageBookPage: action.currentManageBookPage }
        case 'CURRENT_BOOKS_APPROVAL_IN_PROGRESS_PAGE':
                return { ...state, currentBooksApprovalInProgressPage: action.currentBooksApprovalInProgressPage }        
        case 'CURRENT_BOOKS_APPROVAL_READY_OF_SALE_PAGE':
                return { ...state, currentBooksApprovalReadyofSalePage: action.currentBooksApprovalReadyofSalePage }
        case 'MANAGE_BOOKS_SORT_OPTION':
                return { ...state, manageBookSortOption: action.manageBookSortOption  }
        case 'BOOKS_APPROVAL_SORT_OPTION':
                return { ...state, booksApprovalSortOption: action.booksApprovalSortOption  }
        case 'MANAGE_REVIEW_SORT_OPTION':
                return { ...state, manageReviewSortOption: action.manageReviewSortOption  }               
                
        // เก็บค่าตำแหน่งที่อยู่หน้าเว็บ
        // case 'CURRENT_SCROLLY_BOOK_PAGE': 
        //         return { ...state, currentScrollY: action.currentScrollY }
        default:
            return state
    }
}
  
export default site