
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Button from 'react-bootstrap/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import accessPath, { getBannerDetails } from '../../api'
import { useNavigate, useLocation } from 'react-router-dom';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { useDispatch, useSelector } from 'react-redux';
import { isValidUrl, insertBanner, getAutocompleteEbooks, editBannerDetails, uploadFileBannerImage, updateImgBannerUrl } from '../../api'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Spinner from 'react-bootstrap/Spinner';

const AddBanner = () => {
    const [autocompleteList, setAutocompleteList] = useState([]);
    const [bannerDetails, setBannerDetails] = useState([]);
    const [publishDate, setPublishDate] = useState(null);
    const [unPublishDate, setUnPublishDate] = useState(null);
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState('');
    const [status, setStatus] = useState('0');
    const [typeBook, setTypeBook] = useState('book');
    const [checkButtonLoading, setCheckButtonLoading] = useState('1');
    const [selectedBookOption, setSelectedBookOption] = useState(null);
    const [bannerContent, setbannerContent] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const { bannerId } = location.state || {};
    const [selectedBookId, setSelectedBookId] = useState(null);
    const [fileBannerImage, setFileBannerImage] = useState('');
    const [uploadBannerImageName, setUploadBannerImageName] = useState('');
    const [selectedFileCoverImage, setSelectedFileEbook] = useState('');
    const [publishDateError, setPublishDateError] = useState(false);
    const [unpublishDateError, setUnpublishDateError] = useState(false);

    useEffect(() => {
        if (bannerId == 'undefined' || bannerId == '' || bannerId == null || bannerId == undefined) {
            // do nothing
        } else {
            showBannerDetails();
        }
    }, []);

    const handleStatus = (event) => {
        setStatus(event.target.value);
    }

    const handleTypeBook = (event) => {
        setTypeBook(event.target.value);

        if (event.target.value != 'book') {
            setSelectedBookId(null);
            setbannerContent(null);
        } else {
            setSelectedBookOption(null);
            setAutocompleteList([]); 
        }
    }

    const useRefReferenceName = useRef();
    const useRefCustomURL = useRef();
    const uploadImageRef = useRef();

    const debouncedSearch = useDebounce((term) => {
        // Call your API with the search term
        getAutoCompleteEbookItems(term);
      }, 300); // Del

    const handleOnInputChange = (event, value = '') => {
        if (value === '') {
            setSelectedBookOption(null);
            setAutocompleteList([]);
        } else {
            debouncedSearch(value);
        }
    };

    // Debounce helper function
    function debounce(func, delay) {
        let timerId;
        return (...args) => {
            clearTimeout(timerId);
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    // delay call api for continues typing
    function useDebounce(callback, delay) {
        const debouncedCallback = useRef(debounce(callback, delay)).current;
    
        useEffect(() => {
        return () => {
            clearTimeout(debouncedCallback.timerId);
        };
        }, [debouncedCallback]);
    
        return debouncedCallback;
    }

    const handleBookSelected = (event, value) => {
        setSelectedBookId(value ? value.ebook_id : '');
        setSelectedBookOption(value);
    };

    const defaultMinDate = new Date(2024, 0, 1, 0, 0, 0);

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const refreferenceName = useRefReferenceName.current.value;

        var linkTypeContent = null;

        if (typeBook == 'book') {
            linkTypeContent = selectedBookId;
        } else {
            if(isValidUrl(useRefCustomURL.current.value)){
                linkTypeContent = useRefCustomURL.current.value;
            }
        }

        if(linkTypeContent == null || linkTypeContent == ''){
            event.stopPropagation();
            if(typeBook == 'book'){
                showAlertDialog("Error","Link type is required, please select a book.")
            }else{
                showAlertDialog("Error","URL is not valid")
            }
            
            return;
        }

        if(publishDate == null || publishDate == ""){
            event.stopPropagation();
            showAlertDialog("Error","Publish date is required, please select publish date.")
            
            return;
        }

        if(publishDate != null && publishDate != "" && publishDateError != null && publishDateError != ""){
            event.stopPropagation();
            showAlertDialog("Error","Publish date is not valid.")
            return;
        }

        if(unPublishDate != null && unPublishDate != "" && unpublishDateError != null && unpublishDateError != ""){
            event.stopPropagation();
            showAlertDialog("Error","Unpublish date is not valid.")
            return;
        }

        const date = new Date(publishDate);
        const strPublisgDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

        let strUnPublishDate = null;
        if (unPublishDate) {
            const date2 = new Date(unPublishDate);
            strUnPublishDate = `${date2.getFullYear()}/${(date2.getMonth() + 1).toString().padStart(2, '0')}/${date2.getDate().toString().padStart(2, '0')} ${date2.getHours().toString().padStart(2, '0')}:${date2.getMinutes().toString().padStart(2, '0')}`;
        }

        if (form.checkValidity() === true) {
            event.preventDefault();
            event.stopPropagation();

            setCheckButtonLoading('2');

            if (bannerId == 'undefined' || bannerId == '' || bannerId == null || bannerId == undefined) {
                insertBanner(refreferenceName, linkTypeContent, status, strPublisgDate, strUnPublishDate, fileBannerImage, typeBook, selectedFileCoverImage).then((response) => handleAfterInsert(response));
            } else {
                editBannerDetails(refreferenceName, linkTypeContent, status, strPublisgDate, strUnPublishDate, fileBannerImage, typeBook, selectedFileCoverImage, bannerId).then((response) => handleAfterInsert(response));
            }
        }

        setValidated(true);

    }

    const handleAfterInsert = async (response) => {
        // console.log(response.data);
        const bannerId = response.data;

        if (uploadBannerImageName == true) {
            navigate('/Banner', {replace: true});
        } else {
            uploadBookResource(selectedFileCoverImage, bannerId);
        }

    };

    async function uploadBookResource(file, bannerId) {
        const uploadFilePromise = uploadFileBannerImage(file).then(async response => {
            await updateImgBannerUrl(response.data['tfile'], bannerId).then(async response => {
                navigate('/Banner', {replace: true});
            });

        });
        await Promise.all([uploadFilePromise]);
    }


    const handleCancel = () => {
        navigate('/Banner', {replace: true});
    };

    const handleBackClick = () => {
        navigate('/Banner', { replace: true });
    };

    const handleImageChange = (event) => {
        const acceptedTypes = ["image/jpeg", "image/jpg"];
        const file = event.target.files[0];
        if (!acceptedTypes.includes(file.type)) {
            // alert("ไฟล์รูปภาพต้องเป็น .jpg, .jpeg, หรือ .png เท่านั้น");
            dispatch(dialogConfirmSetData({
                title: "Error",
                message: "Supported file extensions .jpg .jpeg only",
                closeButton: "",
                confirmButton: "OK",
                eventConfirmButton: () => null,
                eventCloseButton: () => null,
                showCloseButton: false
            }));
            // ** ทำให้ dialog โชว์ขึ้นมา **
            dispatch(dialogConfirmSetShowModal(true));
            // เคลียร์ input
            uploadImageRef.current.value = null;
            return;
        }

        const namefile = generateUniqueFileName(file.name)
        const newFile = new File([file], namefile, { type: file.type });
        setSelectedFileEbook(newFile);
        setFileBannerImage(URL.createObjectURL(file));
        setUploadBannerImageName(false);
    }

    function generateUniqueFileName(originalName) {
        const timestamp = Date.now();
        const fileExtension = originalName.split('.').pop();
        return `ebook-banner-${timestamp}.${fileExtension}`;
    }

    const confirmRemoveBannerImage = () => {

        dispatch(dialogConfirmSetData({
            title: "Confirm clear",
            message: "This will remove the selected file from the upload.",
            closeButton: "Cancel",
            confirmButton: "Remove",
            eventConfirmButton: () => setFileBannerImage(""),
            eventCloseButton: () => null,
            showCloseButton: true
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    }

    const showAlertDialog = (title, message) => {

        dispatch(dialogConfirmSetData({
            title: title,
            message: message,
            closeButton: "",
            confirmButton: "OK",
            eventConfirmButton: () => null,
            eventCloseButton: () => null,
            showCloseButton: false
        }));
        // ** ทำให้ dialog โชว์ขึ้นมา **
        dispatch(dialogConfirmSetShowModal(true));

    }


    //defaultValue={formData.isbn || datagetEbookDetails['isbn_number']}defaultValue={bannerDetails['display_order']}
    function showBannerDetails() {
        getBannerDetails(bannerId).then(response => {
            setBannerDetails(response.data[0]);
            setPublishDate(new Date(response.data[0]['publish_date']));
            if (response.data[0]['unpublish_date']) {
                setUnPublishDate(new Date(response.data[0]['unpublish_date']));
            }
            
            
            const ebookId = response.data[0]['banner_content'];
            setbannerContent(response.data[0]['banner_content']);
            setTypeBook(response.data[0]['banner_type']); //banner_type
            setStatus(response.data[0]['active']);
            setSelectedBookId(ebookId);
            setUploadBannerImageName(true);
            setFileBannerImage(accessPath + '/' + response.data[0]['image_url']);
            let titleName = '';
            getAutocompleteEbooks(titleName = '', ebookId).then(response => {
                setAutocompleteList(response.data);
                //console.log(response.data);
                setSelectedBookOption(response.data[0]);
            });


        });
    }

    function getAutoCompleteEbookItems(titleName) {
        let id = '';
        getAutocompleteEbooks(titleName, id = '').then(response => {
            setAutocompleteList(response.data);
        });
    }

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <Row>
                                <div className='col-md-12' >
                                    <Row>
                                        <div className='col-md-6'>
                                            <span className='title-text'>
                                                <div className='title-content-text' onClick={() => handleBackClick()}>Banner 
                                                
                                                </div>&nbsp;&nbsp;&nbsp;&nbsp; {'›'} &nbsp;&nbsp;&nbsp;&nbsp; 
                                                {
                                                bannerId == null || bannerId === '' ? (
                                                     'Add'
                                                    ) : (
                                                       'Edit'
                                                    )}  
                                                       &nbsp;&nbsp;
                                                      {' '}Feature Banner
                                                     </span>
                                            <p className='context-text'>Banners description.</p>
                                        </div>
                                        <div className='col-md-6 col-6-div' style={{ textAlign: 'right' }}>
                                            <Row>
                                                <Col sm={5} >
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                            <br></br>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col >
                                            <main>
                                                <div className='box-table-Account2'>
                                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Reference Name<span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <Form.Control required type="text" maxLength={45} ref={useRefReferenceName} defaultValue={bannerDetails['reference_name']} name="referenceName" />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Link Type<span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="5">

                                                                <div>
                                                                    <Form.Check
                                                                        label="Book"
                                                                        type='radio'
                                                                        name='typeBook'
                                                                        value='book'
                                                                        checked={typeBook === 'book'}
                                                                        onChange={handleTypeBook}
                                                                        inline
                                                                    />

                                                                    <Form.Check
                                                                        label="Custom URL"
                                                                        type='radio'
                                                                        name='typeBook'
                                                                        value='custom'
                                                                        checked={typeBook === 'custom'}
                                                                        onChange={handleTypeBook}
                                                                        inline

                                                                    />
                                                                </div>

                                                                {typeBook === 'custom' ? (
                                                                    <div className='AutocompleteInput'>
                                                                        <Form.Control 
                                                                            required
                                                                            type="text" 
                                                                            maxLength={200} 
                                                                            ref={useRefCustomURL} 
                                                                            defaultValue={bannerContent} 
                                                                            name="textCustomUrl"
                                                                            />
                                                                    </div>
                                                                ) : (
                                                                    <div className='AutocompleteInput'>
                                                                        <Autocomplete
                                                                            disablePortal
                                                                            id="autocompleteBookName"
                                                                            onInputChange={handleOnInputChange}
                                                                            onChange={handleBookSelected}
                                                                            options={autocompleteList}
                                                                            value={selectedBookOption}
                                                                            isOptionEqualToValue={(option, value) => option.ebook_id === value.ebook_id}
                                                                            getOptionKey={(option) => option.ebook_id}
                                                                            getOptionLabel={(option) => option.label}
                                                                            sx={{
                                                                                width: '100%',
                                                                                display: 'inline-block',
                                                                                '& input': {
                                                                                    height: 5,
                                                                                    bgcolor: 'background.paper',
                                                                                    color: (theme) =>
                                                                                        theme.palette.getContrastText(theme.palette.background.paper),
                                                                                },
                                                                            }}
                                                                            renderInput={(params) => <TextField {...params} />}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </Col>
                                                        </Form.Group>





                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">Status</Form.Label>
                                                            <Col >
                                                                <Form.Check
                                                                    label="Active"
                                                                    type='radio'
                                                                    name='Status'
                                                                    value='1'
                                                                    checked={status === '1'}
                                                                    onChange={handleStatus}
                                                                    inline

                                                                />

                                                                <Form.Check
                                                                    label="Inactive"
                                                                    type='radio'
                                                                    name='Status'
                                                                    value='0'
                                                                    checked={status === '0'}
                                                                    onChange={handleStatus}
                                                                    inline

                                                                />
                                                            </Col>
                                                        </Form.Group>




                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Publish Date <span className='star'>*</span>
                                                            </Form.Label>
                                                            <Col sm="5" className='date-time-input'>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateTimePicker
                                                                        isBeforeDay={true}
                                                                        onChange={setPublishDate}
                                                                        onError={(newError) => setPublishDateError(newError)}
                                                                        minDate={dayjs(defaultMinDate)}
                                                                        format="DD/MM/YYYY HH:mm"
                                                                        slotProps={{
                                                                            field: {
                                                                                clearable:true
                                                                            }
                                                                        }}
                                                                        ampm={false}
                                                                        sx={{ width: '100%'}}
                                                                        value={publishDate != null ? dayjs(publishDate) : null} 
                                                                        />
                                                                </LocalizationProvider>
                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="4">
                                                                Unpublish Dete
                                                            </Form.Label>
                                                            <Col sm="5" className='date-time-input'>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <DateTimePicker
                                                                        isBeforeDay={true}
                                                                        onChange={setUnPublishDate}
                                                                        onError={(newError) => setUnpublishDateError(newError)}
                                                                        minDate={dayjs(defaultMinDate)}
                                                                        format="DD/MM/YYYY HH:mm"
                                                                        slotProps={{
                                                                            field: {
                                                                                clearable: true,
                                                                            }
                                                                        }}
                                                                        ampm={false}
                                                                        sx={{ width: '100%'}}
                                                                        value={unPublishDate != null ? dayjs(unPublishDate) : null} 
                                                                        />
                                                                </LocalizationProvider>
                                                            </Col>
                                                        </Form.Group>


                                                        <Form.Group as={Row} className="mb-3" controlId="">

                                                            <Form.Label column sm="4">
                                                                <div style={{ paddingBottom: '60px' }} >
                                                                    <span className='my-rowlabel'>Banner Image - App <span className='star' >*</span> </span>
                                                                </div>
                                                            </Form.Label>
                                                            <Col sm="5">

                                                                {(fileBannerImage) ?
                                                                    <div>
                                                                        <div className="image-frame" >
                                                                            <img src={fileBannerImage} alt="Selected" style={{ maxHeight: '250px' }} />
                                                                        </div>
                                                                        <div>
                                                                            <span className='text-alerts' ></span>
                                                                        </div>
                                                                        <a onClick={() => confirmRemoveBannerImage()}
                                                                            className='delete-link link-button'>
                                                                            Delete
                                                                        </a>
                                                                    </div>
                                                                    :
                                                                    <div>
                                                                        <Form.Control ref={uploadImageRef} required type="file" accept="image/jpeg" value={formData.uploadImageRef} name="uploadImageRef" onChange={handleImageChange} />
                                                                        <div>
                                                                            <span className='text-alerts' >Image Size 2160 x 1210 px</span>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </Col>
                                                        </Form.Group>

                                                        <center>
                                                            
                                                        {checkButtonLoading === '1' && (
                                                            <div>
                                                                <Button className='button-form-cancel button-main button-white' onClick={handleCancel} variant="primary">
                                                                    Cancel
                                                                </Button>
                                                                <Button className='button-form-save button-main button-orange' type="submit">
                                                                    Save
                                                                </Button>
                                                            </div>
                                                        )}

                                                            {checkButtonLoading == '2' && (
                                                                <Spinner animation="border" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </Spinner>
                                                            )}
                                                        </center>
                                                        
                                                    </Form>
                                                </div>
                                                
                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
    );
};

export default AddBanner;
