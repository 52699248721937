import React, { useEffect, useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { useSelector } from 'react-redux';

const Loading = () => {
    const loadingStatus = useSelector(state => state.site.showLoadingStatus);
    const [status, setStatus] = useState('hide');

    useEffect(() => {

        if (loadingStatus === true) {
            setStatus('show');
        } else {
            setStatus('hide');
        }

    }, [loadingStatus])

    return (
        <div id='loading-container' className={"loading-container-" + status}>
            <Spinner animation="border" role="status"  style={{color: '#FF8700'}}>
                {/* <span className="visually-hidden">Loading...</span> */}
                {/* <span style={{ padding: '10px'}} className="visually-show">Loading...</span> */}
            </Spinner>
            {/* <span className="loading-txt">Loading...</span> */}
        </div>
    )
}

export default Loading
