import React from 'react'

function PrivacyPolicyPage() {
  return (
    <div className="terms-and-privacy-page" style={{marginLeft: '30px', marginRight: '30px'}}>
    <h4 className='text-left my-3'><strong>Privacy Policy</strong></h4>
    
    
    
    <p>Flixer Company Limited deeply realizes how importance personal data protection is. According to Personal Data Protection Act, B.E. 2562 and the related laws of Thailand, it is social responsibilities for the company to issue privacy policy reach the legal standard for our users in any platform. Therefore, our users can trust that the company shall manage and secure suitably all data in sufficient security measures for collecting, using and disclosing under the laws of Kingdom of Thailand.</p>
    
    
    
    <p><strong>1. Definition&nbsp;</strong></p>
    
    
    
    <p>“Committee” means the Personal Data Protection Committee; “Competent Official” means any person appointed by the Minister to perform acts under this Act; “Office” means the Office of the Personal Data Protection Committee;&nbsp;</p>
    
    
    
    <p>“Company“ means Flixer Company Limited as known as we in this policy;</p>
    
    
    
    <p>“Cookie” means small text files that websites place on your device as you are browsing when connected to the internet. This is to collect your personal data. The cookies will be sent to the origin website every time you visit the website;</p>
    
    
    
    <p>“Data” means any person who operates in relation to the collection, use, or disclosure of the personal data pursuant to the orders given by or on behalf of a data controller;</p>
    
    
    
    <p>“Data Processor” means a Person or a juristic person who operates in relation to the collection, use, or disclosure of the Personal Data pursuant to the orders given by or on behalf of a Data Controller, whereby such Person or juristic person is not the Data Controller;&nbsp;</p>
    
    
    
    <p>“PDPA” means Personal Data Protection Act, B.E. 2562</p>
    
    
    
    <p>“Personal Data” means any information relating to a Person, which enables the identification of such Person, whether directly or indirectly, but not including the information of the deceased Persons in particular;&nbsp;</p>
    
    
    
    <p>“Platform” means all Flixer’s platform;&nbsp;</p>
    
    
    
    <p>“Secretary-General” means the Secretary-General of the Personal Data Protection Committee;</p>
    
    
    
    <p>“Sensitive data” means racial or ethnic origin, political opinions, religious or philosophical beliefs, person’s sex life, criminal records, data concerning health, trade union membership, the processing of genetic data, biometric data or any other data for the purpose of uniquely identifying a natural person as per the Announcement of the commission;</p>
    
    
    
    <p>“Minister” means the Minister who is in charge under this Ac</p>
    
    
    
    <p>“User” or “Users” means a Flixer user;</p>
    
    
    
    <p><br/><strong>2. Why do we have privacy policy?&nbsp;</strong></p>
    
    
    
    <p>The purpose for privacy policy is for our users to trust that the company shall collect, use and disclose our user’s data in the suitable standard according to PDPA.&nbsp;</p>
    
    
    
    <p><strong>&nbsp;3. How do we collect your data?&nbsp;</strong></p>
    
    
    
    <p>We will collect user’s data, e.g. email, Facebook information, name, id, using activities, cookies, subscription, directly and indirectly from our platforms by ourself, our related company and third parties. The types of data collected by us and examples are shown in the following types;&nbsp;</p>
    
    
    
    <p><strong>3.1 Personal data:</strong> e.g. title, first name, last name, gender, age, occupation, job title, position, type of business, nationality, ethnicity, religion, date of birth. Marital status, marriage certificate, number of family members and children Information on government-issued cards (for example, personal identification number Copy of ID card Passport number Driver’s license details), house registration, signature, voice recording, pictures, photographs, video recordings, video clips, educational background, workplace, electronic identity verification (e-KYC) data, income level and income/salary/bonus. weight and height closed circuit television recording vehicle license plate details driver’s license picture Vehicle registration manual Vehicle details (e.g. body number and vehicle license plate number) Copy of policy Relationship between policyholder or claim user policy and electronic insurance policies;</p>
    
    
    
    <p><strong>3.2 Contact data</strong>: e.g. address, delivery details Billing details phone number mobile number Work phone number, email, work email Line account name, Facebook account, Google account, Twitter account and other accounts related to social networking sites;</p>
    
    
    
    <p><strong>3.3 Account data:</strong> e.g. credit/debit card numbers Credit/Debit Card Information Bank account number, member code, customer code, membership type, customer type, Rabbit card number Rabbit LINE Pay account name customer credit score Application for goods and services (e.g. service registration form Application for Financial Services or Insurance) Month of membership/service subscription and payment details and a copy of the bank account / bank book Such information may be collected directly from us. and/or third parties providing payment services;</p>
    
    
    
    <p><strong>3.4 Transaction data:</strong> e.g. payment information Card usage and transaction data Advertising campaign response data Payment slip details about refund refund amount date spent Order/Order number Appointment date Complaints and claims, transactions, transaction history, location, transaction status, past sales transactions and spending behavior as well as other details of the products and services you have purchased;</p>
    
    
    
    <p><strong>3.5 Technical data:</strong> e.g. Internet Portal Address (IP Address), Web Beacon, Log, device type. Hardware reference identifiers e.g. Universal Device Identifier (UDID) or MAC Address. Software reference identifiers e.g. Advertising Identifier Information for iOS (IDFA) or Android Advertising Identifier Information (AAID). link Access details Single Authentication (SSO) Service Access Length of time spent visiting our pages, cookies, your login information. search history Search details Browser type and version Location and location of the time zone Browser plug-in type and version Operating system and platform and other technologies on the device you use to access the Platform;</p>
    
    
    
    <p><strong>3.6 Behavioral data:</strong> e.g. information about your spending habits. and information from your use of our products and services, e.g. your location;</p>
    
    
    
    <p><strong>3.7 Profile data:</strong> e.g. username and password, profile, spending, order history. past orders purchase history purchase item Number of purchases per item Canceling an order or returning a product from you Order via website Order ID financial records Identification Number (PIN) Information about interests, likes, feedback and survey responses. satisfaction survey results social media use Details of participation in the event Loyalty Program Using your discount code and promotion Customer order details customer service Attending exhibitions and trade shows;</p>
    
    
    
    <p><strong>3.8 Service data:</strong> details e.g. information about your use of the website, platform, products and services. Q&amp;A record and/or;</p>
    
    
    
    <p><strong>3.9 Marketing and communication data:</strong> e.g. your preferences in receiving marketing communications from us. affiliates and subsidiaries, third parties, business partners and your communication preferences;</p>
    
    
    
    <p><strong>3.10 Other data: </strong>e.g. the data to improve and to develop our service.&nbsp;</p>
    
    
    
    <p>In cases users provide us the aforesaid data of other people to us, e.g. name, ID number, phone number whether it is for our delivery, our service and emergency contact, the users confirm that the users have a right to provide the mentioned data and consent us to use data under this privacy policy.&nbsp;</p>
    
    
    
    <p>We have no intention to collect, to use and to disclose user’s <strong>sensitive data</strong> without the user’s explicit consent only allowed by the users and the law fairly and lawfully, and adequate. &nbsp;</p>
    
    
    
    <p>***We shall collect data of minor, quasi-incapable and incapable only allowed by the parent, guardian and curator. If we found that the mentioned data are collected without the consent, we will immediately delete the data unless the law allow to collect it.&nbsp;</p>
    
    
    
    <p><br/><strong>4. Why do we collect your data?&nbsp;</strong></p>
    
    
    
    <p>We may collect, use and disclose your data for the following purposes.&nbsp;</p>
    
    
    
    <p><strong>4.1 Purposes based on user’s consent</strong></p>
    
    
    
    <p>With user’s consent, the data shall be collected, used and disclosed by the company, associated company, sister company, our business partner regarding the following purposes;&nbsp;</p>
    
    
    
    <p><strong>4.1.1 marketing and communication:</strong> for marketing communication, providing information, special offer, promotion, news, online and offline marketing from the company, associated company, sister company, our business partner.</p>
    
    
    
    <p><strong>4.1.2 Data Analytics:</strong> data analysis for the better quality of our services.</p>
    
    
    
    <p><strong>4.1.3 Other businesses:</strong> To engage in other businesses excepted Point 4.1.1 and Point 4.1.2, e.g. digital marketing and related goods and services.</p>
    
    
    
    <p><strong>4.2 Purposes based on legal grounds to process user’s personal data &nbsp;</strong></p>
    
    
    
    <p>We may rely on (1) terms and conditions between the company and user (2) performance for our legal duty (3) legitimate interests for our legitimate interests and third parties, we will balance our legitimate interests and the third parties, who involve with user’s benefits including fundamental rights and freedoms in relation to PDPA (4) the prevention or suppression of harm to the life, body or health of any person; and/or (5) the public interest base; to process for public benefits or for the exercise of the authority of an officer, for the following purposes;&nbsp; &nbsp; &nbsp;</p>
    
    
    
    <p><strong>4.2.1 To provide you with our services:&nbsp;</strong></p>
    
    
    
    <p>To register and/or to enable users to use our services, or for commercial registration, to enable users to use our platform and website, to support and carry out other activities related to the sale of our services through online and offline, to sell products or services of our business partners, to provide online media and digital marketing services, to process transactions between users and our business partners, to manage subscription, bookings and to process financial transactions as well as services related to payment. This includes checking verification and to cancel transactions, to process user’s order, delivery, suspension, replacement, reimbursement, return and exchange of our services, and to provide customer services;</p>
    
    
    
    <p><strong>4.2.2 For Marketing Communications:</strong>&nbsp;</p>
    
    
    
    <p>For marketing communications, information, special offers, promotional materials. telemarketing, privileges, advertising, newsletters, marketing and other communications online and offline about our services from our company, our associated companies and business partners;</p>
    
    
    
    <p><strong>4.2.3</strong> <strong>To offer users for promotions, special offers, marketing communications and other offers:</strong>&nbsp;</p>
    
    
    
    <p>To enable users to participate in the promotion. promotional campaigns, special offers, promotional offers loyalty program registration programs with our business partners, and other offers (e.g. to send email notifications), to assess users entire usage history both online and offline, and to make and issue invoices and related documents. To provide users for advertising campaigns, advertisements, necessary notifications, marketing message and marketing information, all marketing communications, advertising campaigns, advertisements, necessary notifications,&nbsp; about our services, brands and operations to users;</p>
    
    
    
    <p><strong>4.2.4 To manage our relationship with users and know users better:</strong>&nbsp;</p>
    
    
    
    <p>To communicate with users about services from our company, our associated companies and business partners, for customer services, to respond to inquiries, requests, feedbacks, complaints, claims, disputes or compensation, to provide technical assistance and to deal with issues. technically, to process and update your information and to facilitate you in using our services. Besides, to learn about the products and services you use. and other products and services that you may be interested in receiving more This includes characterizing the processing of your personal data, for example, based on the types of products and services you receive from us. your satisfaction with how you will be contacted, etc.&nbsp;</p>
    
    
    
    <p><strong>4.2.5 To monitor, analyse, and analyse data:</strong>&nbsp;</p>
    
    
    
    <p>To measure user’s engagement with our services, describe the characteristics of the data, and analyse data, for market research, surveys, assessments, behaviors, statistics and market segmentation. trends and consumption patterns, to know you better and understand your characteristics, to improve business operations, to better adaptation our content to the individual preferences of our customers, to assess performance our promotional campaigns, to identify and troubleshoot existing services, to enhance the development of qualitative information, to know if users have a relationship with a business partner, and to provide marketing activities to our business partners via Facebook. or joint registration page or any other social media or communication platform.</p>
    
    
    
    <p><strong>4.2.6 To select and offer products or services that are likely to be of interest and suitable for user’s needs:</strong>&nbsp;</p>
    
    
    
    <p>We, our associated companies and business partners use the results of the audit and data matching, data characteristics distribution and analytic information from user’s personal data to offer users services that may be of interest to you, to identify user’s preferences and to provide users with personalised experiences, and to develop content articles in the future to reach user’s interests.</p>
    
    
    
    <p><strong>4.2.7 To improve business operations and services:</strong>&nbsp;</p>
    
    
    
    <p>To evaluate, develop, manage and improve existing services, systems and business operations. to design new services, systems and business operations to a data owner and all users by the company, our associated companies and business partners&nbsp;</p>
    
    
    
    <p><strong>4.2.8 To manage information technology systems and to remain our platform performance of our platforms:</strong>&nbsp;</p>
    
    
    
    <p>For the purpose of managing our business, this includes our information technology operations. communication system management Implementation and auditing of information technology security, for internal business management in accordance with internal corporate governance requirements, policies and procedures. and to update our database. Besides, to administer, operate, monitor, observe and manage the website and mobile applications and our platform to help facilitate and function properly efficiently and safely, to facilitate and enhance your experience of using our platforms is even better and to develop sitemaps and contents on our platforms. &nbsp;</p>
    
    
    
    <p><strong>4.2.9 To perform duties in accordance with rules and regulations and to detect, to deter and to prevent fraud/illegal acts::</strong>&nbsp;</p>
    
    
    
    <p>To perform legal duties, legal process or official government orders of Thailand, or overseas government order cooperating with courts of Thailand, official Thai government regulators and law executors, when there is a reason to believe that we have a legal obligation to comply with. For the disclosure of personal information, it is imperative for users to comply with user’s legal obligations, legal process or the order of the aforesaid state including Issuing tax invoices or tax returns, to perform legal duties related to e-Payment, finance and anti-money laundering operations, to record and maintain communications for disclosure to the Revenue Officer Financial Services Business Regulatory Authority, or regulatory agencies and other government agencies, as well as for the investigation or prevention of crimes.&nbsp;</p>
    
    
    
    <p>In addition, to prove and verify your identity, and to verify compliance with other laws and regulations, e.g. Civil and Commercial Code, Computer Crime, and Anti-Money Laundering). To comply with customer identity verification (KYC) or electronic identity verification (e-KYC) processes and to prevent fraud and suspicious transactions);</p>
    
    
    
    <p><strong>4.2.10 To protect our interests:</strong>&nbsp;</p>
    
    
    
    <p>to maintain the safety and integrity of our business operations, to exercise our rights or to defend our interests when it is necessary and legally permitted by law, e.g. to detect, to prevent and to respond to claims about fraud, intellectual property infringement or other violations of law, to manage and prevent loss to our assets and properties, to ensure compliance with terms and conditions, to produce reports on our services to affiliate, and to subsidise to business partners, to detect and prevent misconduct within our facilities, to monitor events, to prevent and report criminal acts and to maintain the safety and integrity of our business. Our interests are included a sale, transfer, merger, reorganization or similar event, we may transfer your Personal Data to any other party who is part of the transaction;&nbsp;</p>
    
    
    
    <p><strong>4.2.11 Risk:</strong>&nbsp;</p>
    
    
    
    <p>To carry out risk management, audit and risk assessment. To check credit and review the customer’s financial status;&nbsp;</p>
    
    
    
    <p><strong>4.2.12 Life:</strong>&nbsp;</p>
    
    
    
    <p>To prevent or stop any harm to the life, body or health of any person.</p>
    
    
    
    <p>Regarding the aforesaid purposes, we need to collect user’s personal data base on legal requirement. If users do not intend to provide the compulsory data required by us, we have to terminate and stop to provide our services to users.&nbsp;</p>
    
    
    
    <p><strong>5. Who may we disclose or transfer user’s personal data with?</strong></p>
    
    
    
    <p>We may disclose or transfer user’s personal data to the following third parties in Thailand and overseas for the processing of personal data according to the purposes under Point 4 of this Privacy Policy. Our third parties are our service providers, our business partners, social networking sites, legal granted third parties, lawyer and accountants for any disputes relating to our company, and other legal relating third parties. For the overseas third parties, we will disclose or transfer properly under the standard of PDPA and related laws unless the transfer or disclosure under the legal conditions, but the third party security standard must depended on their local laws.&nbsp;</p>
    
    
    
    <p><strong>6. How long will we store user’s personal data?&nbsp;</strong></p>
    
    
    
    <p>We shall store user’s personal as long as only for the purposes on Point 4 shall be successful for our user’s and company legal benefits. In contrast, the data might be stored longer than the period under the law, if we need to store a legal claim.</p>
    
    
    
    <p><strong>7. Cookies and how do we use cookies?&nbsp;</strong></p>
    
    
    
    <p>If users access to our platforms. We shall automatically collect information from user’s tracking tools and cookies including Google Tag Manager, Google Analytics, Hotjar Matomo, Zendesk, Facebook Pixel Analytics, Facebook Ad Manager and Google Cloud. In details, cookies are tracking technologies used to analyze trends, to manage our website, to track the movement of website users. or remember users settings. Some cookies are necessaries because, without them, the website can not function properly and other cookies are for the user’s convenience, e.g. cookies that safely remember user’s username. including the language users use.</p>
    
    
    
    <p>Most Internet browsers shall allow users to control whether you accept cookies. If you refuse cookies. Your ability to use some or all functions of our website may be limited.</p>
    
    
    
    <p><strong>8. What are user’s rights under this Privacy Policy?</strong></p>
    
    
    
    <p>Subject to PDPA and relating regulations, a user has rights to:</p>
    
    
    
    <p>8.1 Access or request a copy of your collected personal data from the company.&nbsp;</p>
    
    
    
    <p>8.2 Correct the user’s false personal information.</p>
    
    
    
    <p>8.3 Request user’s collecting personal information.&nbsp;</p>
    
    
    
    <p>8.4 Object to certain processing of user’s personal data, e.g. opting out of direct marketing.</p>
    
    
    
    <p>8.5 Restrict our processing of user’s personal data, if you believe that the information is inaccurate and our actions are unlawful, or we no longer need to process any information for a particular purpose.</p>
    
    
    
    <p>***8.6 Withdraw your consent at any time for the purposes for which users have given us the consent to process user’s personal Data, including opting out of receiving communications and marketing information from us.</p>
    
    
    
    <p>8.7 Request to delete user’s available personal data available that we process is non-personally identifiable information, unless we are under no obligation to comply with the requewst if we are required to retain the information in order to perform our legal obligations. or for exercising our legal rights.</p>
    
    
    
    <p>8.8 To file a petition with officers, if you believe that our processing of personal data unlawfully or non-compliance with applicable PDPA and relating regulations.</p>
    
    
    
    <p>8.9 To be informed of any changes to the Company’s Privacy Policy. We will make an announcement on our platforms that you can easily follow and acknowledge</p>
    
    
    
    <p>*Please note that users need to be identified before using the aforesaid rights.&nbsp;</p>
    
    
    
    <p><strong>9. Security of personal data</strong></p>
    
    
    
    <p>The company has lawfully adequately taken precautions and security measures for user’s personal data from accessing, collecting, using, disclosing, processing, copying, altering, deleting, damaging and misusing, without permission, from any risks of such nature as required by legal standard of PDPA and related regulations.&nbsp;</p>
    
    
    
    <p><strong>10. Our contact details</strong></p>
    
    
    
    <p>If users wish to contact us to exercise your rights in Point 8 relating to user’s personal data, or if you have any questions about Privacy Policy, please contact to our Data Processor at the following details;&nbsp;</p>
    
    
    
    <p><strong>Data Processor:</strong> FLIXER Company Limited</p>
    
    
    
    <p><strong>Address:</strong> 19 Soi Ramkamhaeng 60 Yak9 (Suanson 9), Huamark, Bangkapi, Bangkok 10240</p>
    
    
    
    <p><strong>Email: support@flixerapp.com</strong></p>
</div>
  )
}

export default PrivacyPolicyPage