const initialState = {
    showConfirmDialogModal: false,
    confirmDialogData: {
        title: "",
        message: "",
        closeButton: "",
        confirmButton: "",
        eventConfirmButton: null,
        eventCloseButton: null,
        showCloseButton: false
    }
}

const confirmDialog = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_CONFIRM_DIALOG_MODAL':
            return { ...state, showConfirmDialogModal: action.showConfirmDialogModal}
        case 'CONFIRM_DIALOG_DATA':  
            return { ...state, confirmDialogData: action.confirmDialogData}  
        default:
            return state
    }
}
  
export default confirmDialog