import React from 'react';
import logo from '../../image/logo_main.png'
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import accessPath from '../../api/index.js';

function HeadOnlyLanguageSelection() {

  return (
<>
    <img src={logo} className="App-logo1" alt="logo" width="10%" />
    
    <div style={{textAlign: 'right', 
                  display: 'flex', 
                  justifyContent: 'flex-end', 
                  placeItems: 'center', 
                  paddingTop: '15px', 
                  paddingBottom: '15px'}}>
                    
    

      <div className='col-Profile-in' style={{marginLeft: '10px', marginRight: '10px', width: '80px', height: '50px'}}>
          {/* <Form.Select className='button-language'>
            <option value='xxx'>EN</option>
          </Form.Select> */}
      </div>
      
      
    </div>


    </>

  );
}


export default HeadOnlyLanguageSelection;


