import axios from "axios"
import "../containers/login/LogIn.js";
import Cookies from 'js-cookie';
import { hideLoading } from "./Helper.js";

export const callPostUploadFileEbook = async (url, data, ebookApiUrlDomain) => {

    // get user token to use to upload file
    // ebookApiUrlDomain = ebookApiUrlDomain + "/getApiKeyForUploadFile";
    // const userTokenResponse = await axios.get(ebookApiUrlDomain, { withCredentials: true }, null);
    
    // console.log(url);
    const formData = new FormData();
    formData.append('file', data);
    const response = await axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            // 'Authorization' : "userTokenResponse.data.result.user_token"
        },
        withCredentials:true
    });
    return response;
}



// export const callPost = (url, data) => {
//     let bodyFormData = new FormData();
//     Object.keys(data).forEach(key => {
//         bodyFormData.append(key, data[key])
//     });
//     var callParameters = {
//         method: 'POST',
//         url: url,
//         withCredentials: true,
//         data: bodyFormData,
//         headers: { "Content-Type": "multipart/form-data" }
//     }
//     var tokenKey = Cookies.get('Token') || "";
//     callParameters["headers"]["Authorization"] = tokenKey;
//     return axios(callParameters);
// }



// export const callGet = (url, data) => {  
//     console.log(url);
//     let bodyFormData = new FormData();
//     Object.keys(data).forEach(key => {
//         bodyFormData.append(key, data[key])
//     });
//     var callParameters = {
//         method: 'GET',
//         url: url,
//         withCredentials: true,
//         // data: bodyFormData,
//         headers: { "Content-Type": "multipart/form-data" }
//     }
//     var tokenKey = Cookies.get('Token') || "";
//     callParameters["headers"]["Authorization"] = tokenKey;
//     return axios(callParameters)
// }

export const callPostWithParameterInBody = (url, data) => {
    // console.log('===============data');
    // console.log(data);
    // var tokenKey = Cookies.get('Token') || "";
    // let bodyFormData = new FormData();

    // Object.keys(data).forEach(key => {
    //     bodyFormData.append(key, data[key])
    // });

    // console.log(JSON.stringify(data));

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: url,
            withCredentials: true,
            data: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                // "Authorization": tokenKey
            }
        }).then(
            (response) => {
                handleResponse(url, response, resolve, 'POST')
            }
        ).catch(function (error) {
            handleError(url, error, reject)
        })
    })
}

export const callPost = (url, data) => {
    // console.log('===============data');
    //console.log(data);

    let bodyFormData = new FormData();

    Object.keys(data).forEach(key => {
        bodyFormData.append(key, data[key])
    });
    //console.log(bodyFormData);

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: url,
            withCredentials: true,
            data: bodyFormData,
            headers: {
                "Content-Type": "multipart/form-data",
                // "Authorization": tokenKey
            }
        }).then(
            (response) => {
                handleResponse(url, response, resolve, 'POST')
            }
        ).catch(function (error) {
            handleError(url, error, reject)
        })
    })
}

export const callGet = (url) => {
    // console.log(url);
    // var tokenKey = Cookies.get('Token') || "";

    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: url,
            timeout: 1000 * 30, // Wait for 30 seconds
            withCredentials: true,
            headers: {
                "Content-Type": "multipart/form-data",
                // "Authorization": tokenKey
            }
        }).then(
            (response) => {

                // if( isReceiptValidatorApi(url) ){
                //     handleReceiptValidator(response)
                // }

                handleResponse(url, response, resolve, 'GET')
            }
        ).catch(function (error) {
            handleError(url, error, reject)
        })
    })

}

const handleResponse = (url, response, resolve, type) => {
    // console.log("response " + url + "==> " + JSON.stringify(response.data))

    if (response.data.error == false) {
        resolve(response);
    } else {
        // error จะเข้าตรงนี้
        resolve(response); //เดี๋ยวมาคอมเม้นออก

    }

}

const handleError = (url, response, reject) => {

    hideLoading();
    // alert(response.message);
    reject(response)


}






























