
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { createStore } from 'redux'
import rootReducer from './reducers'
import Loading from './components/global/Loading';

import '../src/css/DatePickerStyles.css'
import 'react-datepicker/dist/react-datepicker.css';
import ModalAlertDialog from './components/global/ModalAlertDialog';

export const store = createStore(rootReducer);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <App />
                <Loading />
                <ModalAlertDialog />
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

reportWebVitals();




