import React from 'react';
import logo from '../../image/logo_main.png'
import Icon_Profile from '../../image/iconprofile.png'
import Icon_Exit from '../../image/exit_icon.png'
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { getUser, wearRights, accessPath, logout } from '../../api/index.js';
import { userSetLevel, userSetProfile, userSetName } from '../../actions/user.actions.js';
import { showLoading, hideLoading } from '../../helper/Helper.js';
import { dialogConfirmSetData, dialogConfirmSetShowModal } from '../../actions/confirmDialog.action.js';
import { setCurrentManageBookPage} from '../../actions/site.action.js';
function Head() {


  const userLevel = useSelector(state => state.user.userLevel);
  const profile = useSelector(state => state.user.profile);
  const userName = useSelector(state => state.user.userName);

  const [email, setEmail] = useState("");
  const [getUserDetails, setUserDetails] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedValueLicense, setSelectedValueLicense] = useState('');
  const handleChangeLicense = (event) => {
    setSelectedValueLicense(event.target.value);
  };
  const haldleClickButtonChangeLicense = () => {
    // นำค่าที่เลือกได้ใน selectedValue ไปใช้งานต่อได้ที่นี่
    // console.log('Selected value:', selectedValueLicense);
    if (selectedValueLicense !== "") {
      wearRights(selectedValueLicense).then(handleWearRights);
    }
  }

  function showConfirmDialog(title, message, buttonTitle, closeButtonTitle, confirmAction, okAction){
    dispatch(dialogConfirmSetData({
        title: title,
        message: message,
        closeButton: closeButtonTitle,
        confirmButton: buttonTitle,
        eventConfirmButton: confirmAction,
        eventCloseButton: okAction,
        showCloseButton: true
    }));

    // ** ทำให้ dialog โชว์ขึ้นมา **
    dispatch(dialogConfirmSetShowModal(true));
  }

  const handleWearRights = async (response) => {
    // console.log(response.data);
    // const encryptedData = response.data["result"]['encryptedToken'];
    // Cookies.set('Token', encryptedData, { path: '/', domain: '.flixerapp.com', expires: 1 });

    if (response.data['isContainPublisherDetail'] === true) {
      navigate('/ManageBooks', {replace: true});
      // window.location.reload();
    } else {
      navigate('/SignUpInfo-Personal', {replace: true});
      // window.location.reload();
    }

  }


  function showUser() {
    getUser().then(handleShowUser)
  }
  const handleShowUser = async (response) => {
    setUserDetails(response.data);
  }

  // console.log(profile);
  const userLogOut = () => {

    var title = "Confirm Logout";
    var message = "Are you sure you want to log out?";
    var buttonCancelTitle = "Cancel";
    var buttoConfirmTitle = "Logout";

    let confirmAction = () => {
      showLoading();

      logout().then((response) => {
        if(response.data["result"]["success"] == true){

          hideLoading();

          dispatch(userSetProfile(null));
          dispatch(userSetLevel(""));
          dispatch(userSetName(""));
          dispatch(setCurrentManageBookPage(1));
          navigate('/',{ replace: true });
          //กำหนดวันที่หมดอายุของ Cookie ให้เป็นวันที่ผ่านมาแล้ว (วันที่ 1 มกราคม 1970 เวลา 00:00:01 GMT) ซึ่งจะทำให้ Cookie นั้นหมดอายุและถูกลบทิ้ง.
          document.cookie = 'ebookCMSUserLogin' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          // window.location.reload();
        } 
      });
    };

    let cancelAction = () => {
      
    };

    showConfirmDialog(title, message, buttoConfirmTitle, buttonCancelTitle, confirmAction, cancelAction)

    
  }

  useEffect(() => {
    if (userLevel == 'admin') {
      showUser();
    }
  }, [profile]);

  useEffect(() => {
    if (userName !== '') {
      setEmail(userName)
    }

  }, [userName]);


  return (
<>
    <img src={logo} className="App-logo1" alt="logo" width="10%" />
    
    <div style={{textAlign: 'right', 
                  display: 'flex', 
                  justifyContent: 'flex-end', 
                  placeItems: 'center', 
                  paddingTop: '15px', 
                  paddingBottom: '15px'}}>
                    
    { (userLevel === 'admin') ?   
    <>     
      <div style={{marginLeft: '10px', marginRight: '5px', width: '200px'}}>
        <Form.Select onChange={handleChangeLicense}>
          <option value=''>Select user</option>
          {getUserDetails.map((option) => (
            <option key={option.email} value={option.email}>
              {option.email}
            </option>
          ))}
        </Form.Select>
      </div>

      <div style={{marginLeft: '5px', marginRight: '10px'}}>
        <Button className='btn button-license' onClick={() => haldleClickButtonChangeLicense()} style={{fontWeight: 'bold', borderRadius: '30px',color: 'white', backgroundColor: 'var(--color-main-theme)', borderColor: 'var(--color-main-theme)', fontSize: '14px', width: '90px', border: '2px solid var(--color-main-theme)'}}>Active</Button>
      </div>
      <div className="vertical-line" style={{width: '25px', marginTop: '0px'}}></div>
      
    </> : '' }

      {/* <div className='col-Profile-in' style={{marginLeft: '10px', marginRight: '10px', width: '80px'}}>
          <Form.Select className='button-language'>
            <option value='xxx'>EN</option>
          </Form.Select>
      </div> */}
      {/* <div className="vertical-line" style={{width: '25px', marginTop: '0px'}}></div> */}
      <div className='col-button-in' style={{marginLeft: '10px', marginRight: '10px'}}>
        <span className='text-profile' style={{fontWeight: 'bold'}}>{userName} </span> <img src={Icon_Profile} width={'50px'} height={'50px'} />
      </div>
      <div className="vertical-line" style={{width: '25px', marginTop: '0px'}}></div>
      <div className='' style={{marginLeft: '10px', marginRight: '0px'}}>
          <a
            onClick={() => userLogOut()}
            style={{ textDecoration: 'none', color: "var(--color-main-theme)", cursor: 'pointer' }} className=''>
            <img src={Icon_Exit} width="15px" />
            <font style={{ fontSize: '15px', marginLeft: '12px', marginRight: '12px' }}><strong>Log Out</strong> </font>
          </a>
      </div>
      
    </div>


    </>

  );
}


export default Head;


