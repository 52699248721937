import React, { useState } from 'react';

const ImageWithPlaceholder = ({ className ,src, placeholder, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div style={{ position: 'relative' }}>
      <img
        src={placeholder}
        alt={alt}
        style={{
          display: loaded ? 'none' : 'block',       
        }}
        className = {className}
      />
      <img
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
        onError={() => setLoaded(false)}
        style={{
          display: loaded ? 'block' : 'none',
        }}
        {...props}
        className = {className}
      />
    </div>
  );
};

export default ImageWithPlaceholder;
