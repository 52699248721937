
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Button from 'react-bootstrap/Button';
import { isNumeric, isLength } from 'validator';

import { editCategoriesDetails, itemCategory } from '../../api/index.js'
import { useLocation,useNavigate } from 'react-router-dom';
import { hideLoading, showLoading } from '../../helper/Helper.js';

const EditCategories = () => {

    const [validated, setValidated] = useState(false);
    const [checkDisplayOrder, setCheckDisplayOrder] = useState('');
    const [formData, setFormData] = useState('');
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const location = useLocation();
    const { categoriesId } = location.state || {}; 

    useEffect(() => {
        if (categoriesId !== null && categoriesId !== undefined) {
            showCategories();
        } else {
            navigate('/Categories',{replace:true});
        }
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        // console.log(value);

        if (name === 'displayOrder') {
            if (!isNumeric(value)) {
                setCheckDisplayOrder('Please enter numbers only.');
            } else {
                setCheckDisplayOrder('');
            }
        }

    }

    function showCategories() {
        itemCategory(categoriesId).then(response => {
            setCategories(response.data[0]);
            setFormData((prevData) => ({ ...prevData, ['status']: response.data[0].status }));
        });
    }
    const handleCancel = () => {
        navigate('/Categories', {replace: true});
    };




    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;

        const categoryNameEng = formData.categoryNameEng || categories.name_en;
        const categoryNameThai = formData.categoryNameThai || categories.name_th;
        let status = formData.status;
        if (status == '' || status == null) {
            status = categories.status;
        }

        const displayOrder = formData.displayOrder || categories.displayOrder;

        if (form.checkValidity() === true) {
            event.preventDefault();
            event.stopPropagation();
            // console.log(formData);
            showLoading();
            editCategoriesDetails(categoriesId, categoryNameEng, categoryNameThai, status, displayOrder).then(handleCategoriesDetailsResponse);
        }

        setValidated(true);
    }

    const handleCategoriesDetailsResponse = async (response) => {
        hideLoading();
        navigate('/Categories', { replace: true });        
    };

    const handleBackClick = () => {
        navigate('/Categories', { replace: true });
    };

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <Row>
                                <div className='col-md-12' >
                                    <Row>
                                        <div className='col-md-6'>
                                        <span className='title-text'> <div className='title-content-text' onClick={() => handleBackClick()}>Categories</div><div style={{marginLeft: '10px',marginRight: '10px'}}>{'›'}</div> Edit Categories  </span>
                                            <p className='context-text'> Edit categories </p>
                                        </div>
                                        <div className='col-md-6 col-6-div' style={{ textAlign: 'right' }}>
                                            <Row>
                                                <Col sm={5} >
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                            <br></br>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col className='' >
                                            <main>
                                                <div className=''>
                                                    <div className=''>
                                                        <div className='content-box-inner'>
                                                            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                                                                <Form.Group as={Row} className="mb-3" controlId="">
                                                                    <Form.Label column sm="4">
                                                                        Category Name - English
                                                                    </Form.Label>
                                                                    <Col sm="5">
                                                                        <Form.Control required type="text" defaultValue={formData.categoryNameEng || categories.name_en} name="categoryNameEng" onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3" controlId="">
                                                                    <Form.Label column sm="4">
                                                                        Category Name - Thai
                                                                    </Form.Label>
                                                                    <Col sm="5">
                                                                        <Form.Control required type="text" defaultValue={formData.categoryNameThai || categories.name_th} name="categoryNameThai" onChange={handleChange} />
                                                                    </Col>
                                                                </Form.Group>


                                                                <Form.Group as={Row} className="mb-3" controlId="">
                                                                    <Form.Label column sm="4">
                                                                        Status
                                                                    </Form.Label>
                                                                    <Col sm="5">
                                                                        <Form.Select required value={formData.status || categories.status} name="status" onChange={handleChange}>
                                                                            <option value='publish' >Publish</option>
                                                                            <option value='draft' >Draft</option>
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Form.Group>

                                                                <Form.Group as={Row} className="mb-3" controlId="">
                                                                    <Form.Label column sm="4">
                                                                        Display Order
                                                                    </Form.Label>
                                                                    <Col sm="5">
                                                                        <Form.Control required type="text" isInvalid={!!checkDisplayOrder} value={formData.displayOrder || categories.displayOrder} name="displayOrder" onChange={handleChange} />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            {checkDisplayOrder}
                                                                        </Form.Control.Feedback>
                                                                    </Col>
                                                                </Form.Group>
                                                                <br />

                                                                <center>
                                                                <Button className='button-form-cancel button-main button-white' onClick={handleCancel} variant="primary">Cancel</Button>
                                                                    <Button className='button-form-save button-main button-orange' type="submit">Save</Button>
                                                                </center>

                                                            </Form>
                                                        </div>
                                                    </div>
                                                </div>

                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
    );
};

export default EditCategories;
