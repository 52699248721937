import { siteSetShowLoadingStatus } from '../actions/site.action';
import { store } from '../index';

export const showLoading = () => {
    store.dispatch(siteSetShowLoadingStatus(true))
}

export const hideLoading = () => {

    let state = store.getState();
    let loadingStatus = state.site.showLoadingStatus;

    if(loadingStatus) {
        // store.dispatch(siteSetShowLoadingStatus(false))
        const timeoutId = setTimeout(() => {
            store.dispatch(siteSetShowLoadingStatus(false))
          }, 500);
      
          // ยกเลิก setTimeout
          return () => clearTimeout(timeoutId);
    }       
}

export const isUserLogedIn = () => {
    let ebookCMSUserLoginCookie = document.cookie.replace(/(?:(?:^|.*;\s*)ebookCMSUserLogin\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if(ebookCMSUserLoginCookie !== null && ebookCMSUserLoginCookie !== '') {
        return true;
    } else {
        return false;
    }
}

export const isAdminLevel = () => {
    let state = store.getState();
    let userLevel = state.user.userLevel;
    if (userLevel !== "" && userLevel === "admin") {
        return true;
    } else {
        return false;
    }
}

export const isPublisherLevel = () => {
    let state = store.getState();
    let userLevel = state.user.userLevel;
    if (userLevel === "guest") {
        // alert('true') 
        return true;
    } else {
        // alert('false')
        return false;
    }
}

export const consoleLogHelper = (title, data = "" ) => {
    // console.log(title, data);
}


