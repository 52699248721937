export const siteSetShowLoadingStatus = showLoadingStatus => ({
    type: 'SITE_SET_SHOW_LOADING_STATUS',
    showLoadingStatus
})

export const setCurrentManageBookPage = currentManageBookPage => ({
    type: 'CURRENT_MANAGE_BOOK_PAGE',
    currentManageBookPage
})

export const setCurrentBooksApprovalInProgressPage = currentBooksApprovalInProgressPage => ({
    type: 'CURRENT_BOOKS_APPROVAL_IN_PROGRESS_PAGE',
    currentBooksApprovalInProgressPage
})

export const setCurrentBooksApprovalReadyofSalePage = currentBooksApprovalReadyofSalePage => ({
    type: 'CURRENT_BOOKS_APPROVAL_READY_OF_SALE_PAGE',
    currentBooksApprovalReadyofSalePage
})

export const setManageSortOption = manageBookSortOption => ({
    type: 'MANAGE_BOOKS_SORT_OPTION',
    manageBookSortOption
})

export const setBooksApprovalSortOption = booksApprovalSortOption => ({
    type: 'BOOKS_APPROVAL_SORT_OPTION',
    booksApprovalSortOption
})

export const setManageReviewSortOption = manageReviewSortOption => ({
    type: 'MANAGE_REVIEW_SORT_OPTION',
    manageReviewSortOption
})

// เก็บค่าตำแหน่งที่อยู่หน้าเว็บ
// export const setCurrentScrollY = currentScrollY => ({
//     type: 'CURRENT_SCROLLY_BOOK_PAGE',
//     currentScrollY
// })