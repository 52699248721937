
import React, { useState, useEffect ,useRef } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import '../../api/index.js';
import '../../helper/ApiHelper.js'
import Button from 'react-bootstrap/Button';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { getCategories, searchCategories ,reorderTableRowIndex , rowReorderCategories } from '../../api/index.js'
import Icon_DotRed from '../../image/dot_red.png';
import Icon_Public from '../../image/dot_green.png';
import { useSelector } from 'react-redux';
const Categories = () => {

    const cellEditCompletePending = useRef(false)
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [formData, setFormData] = useState('');

    function showCategories() {
        getCategories().then(response => {
            setCategories(response.data);
        });
    }


    useEffect(() => {
        showCategories();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target || '';
        setFormData((prevData) => ({ ...prevData, [name]: value }));
        if (event.key === 'Enter') {
            // do whatever
            handleSearch();
        }
    }

    const handleSearch = () => {
        const dataKeyword = formData.keyword || '';
        const columnName = formData.columnName || 'name_en';
        searchCategories(dataKeyword, columnName).then(response => {
            setCategories(response.data);
        });
    }



    const editCategories = (rowData) => {
        navigate('/EditCategories', {
            state: {
                categoriesId: rowData['id']
            },
            // replace: true
        });
        // window.location.reload();
    }

    const handleLinkClick = (url) => {
        // Replace the current URL
        navigate(url);

    };




    ///

    function onRowReorder(event) {
        const id = event.value.map(item => item.id);
        reorderItem(id);
    }

    function reorderItem(id) {
        rowReorderCategories(id).then(response => {
            setCategories(response.data);
        });
    }

    const onCellEditCompleteDisplay = (e) => {
        let id = 'id';
        let normalizedArray =  reorderTableRowIndexCategories(e,id ,categories );
        setCategories(normalizedArray);
         const reorderItemDisplay = normalizedArray.map(item => item[id]);//[12, 11,32,43]
         reorderItem(reorderItemDisplay);
    }


 const reorderTableRowIndexCategories = (event, objectIdKey, sourceItems) => {
    let normalizedArray = sourceItems;
    var moveItem = normalizedArray.filter(item => item[objectIdKey] == event.newRowData[objectIdKey])[0];
    normalizedArray.splice(event.rowIndex, 1);
    var newIndex = parseInt(event.newRowData.rank) - 1;
    normalizedArray.splice(newIndex, 0, moveItem);
    return normalizedArray;
}

const textEditorDisplayOrder = (options) => {
    let hasUpdated = false;

    return (
        <InputText
            type="number"
            value={options.value}
            onChange={(e) => {
                hasUpdated = true; // ติดตามการเปลี่ยนแปลง
                options.editorCallback(e.target.value);
            }}
            onBlur={(e) => {
                if (hasUpdated) {
                    hasUpdated = false; // Reset เมื่อออกจากฟิลด์
                    if (e.target.value !== options.value) {
                        options.editorCallback(e.target.value);
                    }
                }
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter' && hasUpdated) {
                    hasUpdated = false; // Reset เมื่อกด Enter
                    options.editorCallback(e.target.value); // Update on Enter key press
                }
            }}
            style={{ width: '70%' }} // Adjust width
        />
    );
};



    const columns = [
        {
            field: 'rank',
            header: 'No',
            render: (rowData) => (
                <p className='td-text'>
                    {rowData.rank}
                </p>
            ),
            editor: (options) => textEditorDisplayOrder(options),
            onCellEditCompleteDisplay: (event) => {
                // ป้องกันการเรียกซ้ำซ้อน
                if (cellEditCompletePending.current) {
                    return;
                }
            
                // ตั้งค่าเป็น true เพื่อป้องกันการทำงานซ้ำ
                cellEditCompletePending.current = true;
            
                // ตั้งค่า timeout เพื่อป้องกันการเรียกซ้ำจากหลาย event
                setTimeout(() => {
                    // Logic สำหรับการบันทึกผลการแก้ไขเซลล์ที่นี่
                  //  console.log('Cell edited:', event);
                  onCellEditCompleteDisplay(event);
                    // ตั้งค่ากลับเป็น false เมื่อเสร็จสิ้น
                    cellEditCompletePending.current = false;
                }, 0); // ใช้ timeout สั้นๆ เพื่อให้แน่ใจว่าฟังก์ชันทำงานเพียงครั้งเดียว
            }
            
        },
        {
            field: 'name_en',
            header: 'Category Name',
            render: (rowData) => (
                <p className='td-text'>
                    {rowData.name_en}
                </p>
            )
        },
        {
            field: 'total',
            header: 'Match Titles',
            render: (rowData) => (
                <p className='td-text'>
                    {rowData.total}
                </p>
            )
        },
        {
            field: 'status',
            header: 'Status',
            render: (rowData) => (
                <div>
                    {rowData.status === 'draft' ? (
                        <img src={Icon_DotRed} width='12px' className='str_icon' alt='Draft Icon' />
                    ) : rowData.status === 'publish' ? (
                        <img src={Icon_Public} width='12px' className='str_icon' alt='Publish Icon' />
                    ) : (
                        rowData.status
                    )}
                    {rowData.status}
                </div>
            )
        }
    ];

    const dynamicColumns = columns.map((col) => (
        <Column
            key={col.field}
            columnKey={col.field}
            field={col.field}
            header={col.header}
            editor={col.editor}
            onCellEditComplete={col.onCellEditCompleteDisplay}
            className={col.field === 'display_order' ? 'fixed-width-column' : ''}
            body={(rowData) => (
                <div onClick={() => col.field !== 'rank' && col.field !== 'Action' && editCategories(rowData)}>
                    {col.render(rowData)}
                </div>
            )}
            reorderable={false} // ปิดการเรียงลำดับคอลัมน์
        />
    ));


    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>
                        <div className='box-context'>
                            <div className='div-row-col'>
                                <Row sm={12}>
                                    <span className='title-text'>Categories</span>
                                    <p className='context-text'>Manage categories</p>

                                    <Col sm={12} md={5}>
                                        <Button onClick={() => handleLinkClick('/AddCategories')} className='button-action button-main button-orange' variant="primary" type="submit">+ Add Categories</Button>
                                    </Col>
                                    <Col sm={12} md={7}>
                                        <InputGroup>

                                            <Form.Control style={{ width: '150px' }} className='form-control-custom' name='keyword'
                                                onKeyUp={(e) => {
                                                    handleChange(e);

                                                }}
                                                placeholder="Search..." aria-label="Search..." />
                                            <Form.Select name='columnName' onChange={handleChange} className='form-control-custom2' style={{ width: '20px', borderBottomRightRadius: '5px', borderTopRightRadius: '5px' }} >

                                                <option value="name_en" >Category Name</option>
                                                <option value="status" >Status</option>

                                            </Form.Select>

                                            <div md={1} style={{ marginLeft: '10px' }}>

                                                <Button className='button-search button-main button-white' variant="primary" onClick={() => {
                                                    handleSearch();
                                                }} >Search</Button>
                                            </div>
                                        </InputGroup>
                                    </Col>
                                </Row>

                            </div>

                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>
                                        <Col className='' >
                                            <main>
                                                <div className=''>
                                                    <div className=''>
                                                        {/* <Table hover >
                                                            <thead className='thead-text'>
                                                                <tr>
                                                                    <th><p>No</p></th>
                                                                    <th><p>Category Name</p></th>
                                                                    <th><p>Match Titles</p></th>
                                                                    <th><p>Status</p></th>
                                                                    <th><p>Display Order</p></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {categories.map((option, index) => (

                                                                    <tr key={option.id} onClick={() => editCategories(option.id)} style={{cursor: 'pointer'}} title='Click to edit categories.'>
                                                                        <td>  <p className='td-text'>{index + 1} </p> </td>
                                                                        <td>  <p className='td-text'>{option.name_en} </p> </td>
                                                                        <td>  <p className='td-text'>{option.total} </p> </td>
                                                                        <td>  <p className='td-text'>
                                                                            {option.status === 'draft' && (
                                                                                <img src={Icon_DotRed} width='12px;' className='str_icon' />
                                                                            )}
                                                                            {option.status === 'publish' && (
                                                                                <img src={Icon_Public} width='12px;' className='str_icon' />
                                                                            )}
                                                                            {option.status}
                                                                        </p>
                                                                        </td>
                                                                        <td>  <p className='td-text'>{option.rank} </p> </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </Table>
                                                        {(categories.length < 1 && formData.keyword != null) ? 
                                                            <div className='search-no-result'><center><strong>No results for '{formData.keyword}'</strong></center></div>: ''
                                                        } */}

                                                        {/* <DataTable value={categories} reorderableRows onRowReorder={onRowReorder} className="reorder-datatable" tableStyle={{ width: '100%' }}
                                                            editMode="cell" >
                                                            <Column rowReorder className='reorder-datatable-column content-center' />
                                                            {dynamicColumns}
                                                        </DataTable> */}
                                                        <DataTable value={categories} reorderableColumns={false} reorderableRows onRowReorder={onRowReorder} selectionMode="single"  className="reorder-datatable" tableStyle={{ width: '100%' }} editMode="cell" >
                                                            <Column rowReorder className='reorder-datatable-column content-center' />
                                                            {dynamicColumns}
                                                        </DataTable>


                                                    </div>
                                                </div>

                                            </main>
                                        </Col>

                                    </Card.Body>
                                </Card>
                            </div>
                        </div>

                    </main>
                </Col>


            </Row>
        </div >
    );
};

export default Categories;
