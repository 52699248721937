import React, { useEffect, useState } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import TermOfUsePage from '../term-privacy/TermOfUsePage.js';

function Contract() {

  return (
    < >
        <Head />
        <Row className='row-navbar'>
            <NavBar />
            <Col className='row-context' >
                <main>

                    <div className='box-context'>
                        <span className='title-text'> Contract/Agreement </span>
                        <p className='context-text'> Contract, agreement and term of service </p>

                        <div className='box-table'>
                            <Card className='card-box'>
                                <Card.Body>
                                    <TermOfUsePage/>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>

                </main>
            </Col>


        </Row>
    </ >
  )
}

export default Contract