import React from 'react';

import IconDotGreen from '../../image/dot_green.png';
import IconDotRed from '../../image/dot_red.png';
//import IconDotSky from '../../image/dot_sky.png';
import IconDotGray from '../../image/dot_gray.png';
import IconDotYellow from '../../image/dot_yellow.png';
import { STATUS_PREPARE_FOR_SUBMISSION, STATUS_WAITING_FOR_REVIEW, STATUS_READY_FOR_SALE, STATUS_PUBLISHER_REJECTED, STATUS_REJECTED, STATUS_IN_REVIEW, STATUS_PENDING_RELEASE, STATUS_REMOVED_FROM_SALE, STATUS_PUBLISHER_REMOVE_FROM_SALE } from '../../api/index.js'


function StatusEbooks({ status  }) {
   
    let statusInt = parseInt(status);

    if (statusInt == STATUS_PREPARE_FOR_SUBMISSION) {
        return <img src={IconDotGray} width='12px;' className='str_icon' />;
    }
    if (statusInt == STATUS_WAITING_FOR_REVIEW || status == STATUS_IN_REVIEW || status == STATUS_PENDING_RELEASE) {
        return <img src={IconDotYellow} width='12px;' className='str_icon' />;
    }
    if (statusInt == STATUS_READY_FOR_SALE) {
        return <img src={IconDotGreen} width='12px;' className='str_icon' />;
    }
    if (statusInt == STATUS_REJECTED || status == STATUS_REMOVED_FROM_SALE || status == STATUS_PUBLISHER_REMOVE_FROM_SALE || status == STATUS_PUBLISHER_REJECTED ) {
        return <img src={IconDotRed} width='12px;' className='str_icon' />;
    }

    return null;
}

export default StatusEbooks;