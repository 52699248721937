import React from 'react';
import { Link } from 'react-router-dom';
import logoBook from '../../image/logo_main.png';

const PageNotFound = () => {
    return (
        <div className='' style={{textAlign: 'center', verticalAlign: 'middle', marginTop: '280px'}}>
            <div>
                <Link to='/'>
                    <img src={logoBook} className="App-logo" alt="logo" width="250px" style={{marginBottom: '20px'}} />
                </Link>
            </div>
            <h2>This Page Isn't Available.</h2>
            
            <div><Link to='/'><button className='' style={{marginTop: '40px' , paddingTop: '10px', paddingBottom: '10px', paddingLeft: '40px', paddingRight: '40px' , borderRadius: '30px', color: 'black', backgroundColor: 'var(--color-main-theme)', fontWeight: 'bold', fontSize: '20px'}}>Go to Home Page</button></Link></div>
        </div>
    )
}

export default PageNotFound;