import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import { useState, useEffect } from 'react';
import 'react-tagsinput/react-tagsinput.css'
import { convertToNumberWith2Decimal, getCurrencyDisplay, localeString, getEbookItems } from '../../api'
import { useNavigate, useLocation } from 'react-router-dom';
import accessPath from '../../api/index.js';
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder/index.js';
import placeholderImg from '../../image/placeHolder_cover.png'; 


const ViewEbooksAdmin = () => {

    const location = useLocation();
    const { ebookDetailsID, bookTitle , activeTabKey , searchKeyword , searchOption } = location.state || {};;
    const [uploadEBook, setUploadEBook] = useState(null);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [PricingAndSales, setPricingAndSales] = useState('');
    const [publishOption, setPublishOption] = useState('as_review_pass');
    const [selectedOnlyOver, setOnlyOver] = useState('');
    const [datagetEbookDetails, setgetEbookDetails] = useState([]);
    const [tagsDetails, setTagsDetails] = useState([]);
    const [keywordsDetails, setKeywordsDetails] = useState([]);

    function showEbookDetails() {
        getEbookItems(ebookDetailsID).then(handleShowEbookDetails)
    }

    useEffect(() => {
        setPublishOption('as_review_pass');
        setPricingAndSales('for_sale');
        showEbookDetails();
        setOnlyOver('0');
    }, []);

    const handleShowEbookDetails = async (response) => {
        if (response.data[0] === undefined) {
            // navigate('/ManageBooks');
        } else {
            setgetEbookDetails(response.data[0]);

            if (response.data[0]['for_age_18_plus'] == '1') {
                setOnlyOver('1');
            }

            if (response.data[0]['sale_type'] == 'for_sale') {
                setPricingAndSales('for_sale');
            } else {
                setPricingAndSales('free');
            }

            setPublishOption(response.data[0]['publish_options']);

            if (response.data[0]['tags'] != null) {
                const tagsArray = response.data[0]['tags'].split(',');
                setTagsDetails(tagsArray);
            }

            if (response.data[0]['keywords'] != null) {
                const KeywordsArray = response.data[0]['keywords'].split(',');
                setKeywordsDetails(KeywordsArray);
            }

            let urlProfile = accessPath + response.data[0]['cover_image_path'];
            setUploadEBook(urlProfile);

            let urlPdf = accessPath + response.data[0]['uploaded_pdf_path'];
            let uploadedPdfPath = response.data[0]['uploaded_pdf_path'];
            if (uploadedPdfPath != null ) {
                setSelectedPdf(urlPdf);
            }
        }


    }

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/BooksApproval', { 
            replace: true ,
            state: {
                locationActiveTabKey : activeTabKey , 
                searchKeyword: searchKeyword,
                searchOption: searchOption
            }

            
        
        });
    };

    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <span className='title-text'> <div className='title-content-text' onClick={() => handleBackClick()}>Books Approval</div><div style={{marginLeft: '10px',marginRight: '10px'}}>{'›'}</div>{bookTitle}   </span>
                            <p className='context-text'>Showing your book details</p>
                            <Card className='card-box card-box-top-pading'>
                                <Card.Body>
                                    <div className='content-box'>
                                        <Row>
                                            <div className='col-md-12' >
                                                <Row className='align-items-end'>
                                                
                                                    <div className='col-md-6'>
                                                        <span className='inner-title-text'>Book Details</span>
                                                    </div>
                                                    
                                                </Row>
                                            </div>
                                        </Row>

                                        <hr className='hr-title' />
                                        <Form>

                                            <Form.Group as={Row} className="mb-12" controlId="">
                                                <Form.Label column sm="4">
                                                    <div style={{ paddingBottom: '270px' }}>
                                                        <span className='my-rowlabel'>Upload E-Book Cover</span>
                                                    </div>
                                                </Form.Label>
                                                <Col sm="6" style={{ paddingTop: '30px' }}>
                                                    {uploadEBook && (
                                                        <div className="image-frame" >
                                                            <ImageWithPlaceholder
                                                                src={uploadEBook}
                                                                placeholder={placeholderImg}
                                                                className = 'book-overview'
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                            </Form.Group>

                                            <br></br>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Title
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['book_title'] != null && datagetEbookDetails['book_title'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['book_title']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Author Name
                                                </Form.Label>
                                                <Col sm="6">

                                                    {(datagetEbookDetails['book_author'] != null && datagetEbookDetails['book_author'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['book_author']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Category
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['category_name'] != null && datagetEbookDetails['category_name'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['category_name']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">Book Series</Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails.serie_name != null && datagetEbookDetails.serie_name != "") ? 
                                                        <Form.Label>{datagetEbookDetails.serie_name}</Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Label type="text" >{datagetEbookDetails.volume} </Form.Label>
                                                    <span className='text-alerts' >Order of books in this series</span>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Only for People Over 18 Years Old
                                                </Form.Label>
                                                <Col sm="6">
                                                    {selectedOnlyOver === '0' && (
                                                        <Form.Label >No</Form.Label>
                                                    )}

                                                    {selectedOnlyOver === '1' && (
                                                        <Form.Label >Yes</Form.Label>
                                                    )}

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Synopsis
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['book_description'] != null && datagetEbookDetails['book_description'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['book_description']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Book Type
                                                </Form.Label>
                                                <Col sm="6">
                                                    <Form.Label type="text" >
                                                        {
                                                            datagetEbookDetails['book_type'] === '1' ?
                                                                'Thai Book' :
                                                                'Translated Book'
                                                        }
                                                    </Form.Label>

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['isbn_number'] != null && datagetEbookDetails['isbn_number'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['isbn_number']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    ISBN E-Book
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['isbn_ebook_number'] != null && datagetEbookDetails['isbn_ebook_number'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['isbn_ebook_number']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>


                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                Right-to-left reading style
                                                </Form.Label>
                                                <Col sm="6">
                                                {(datagetEbookDetails['reading_mode'] == 'ltr') ?
                                                        <Form.Label type="text" >No </Form.Label>
                                                        :
                                                        <Form.Label type="text" > Yes </Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">Tags</Form.Label>
                                                <Col sm="6">
                                                    {(tagsDetails.length >= 1) ?
                                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-buttonTags'>
                                                            {tagsDetails.map((option) => (
                                                                <a key={option} style={{ marginRight: '2px' }} className="my-LabelTags"> <span className='font-tags'>{option}</span></a>
                                                            ))}
                                                        </div>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }

                                                </Col>

                                            </Form.Group>

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Keywords
                                                </Form.Label>
                                                <Col sm="6">
                                                    {(keywordsDetails.length >= 1) ?
                                                        <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }} className='div-my-button'>
                                                            {keywordsDetails.map((option) => (
                                                                <a key={option} style={{ marginRight: '2px' }} className="my-LabelKeywords"> <span className='font-tags'>{option}</span></a>
                                                            ))}
                                                        </div>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }

                                                </Col>
                                            </Form.Group>

                                            <br></br>
                                            <span className='inner-title-text'>Pricing and Sales</span>
                                            <hr className='hr-title' />
                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Type Sales
                                                </Form.Label>
                                                <Col sm="6">

                                                    {PricingAndSales === 'for_sale' && (
                                                        <Form.Label >
                                                            Books for Sale
                                                        </Form.Label>
                                                    )}

                                                    {PricingAndSales === 'free' && (
                                                        <Form.Label >
                                                            Free Books
                                                        </Form.Label>
                                                    )}

                                                </Col>
                                            </Form.Group>

                                            {PricingAndSales === 'for_sale' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Paper Cover Price
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            {(datagetEbookDetails['cover_price'] != null && datagetEbookDetails['cover_price'] !== '') ?
                                                                <Form.Label type="text" >{getCurrencyDisplay(convertToNumberWith2Decimal(datagetEbookDetails['cover_price']))} </Form.Label>
                                                                :
                                                                <Form.Label type="text" > - </Form.Label>
                                                            }
                                                        </Col>
                                                    </Form.Group>

                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">
                                                            Book Price
                                                        </Form.Label>
                                                        <Col sm="6">
                                                            {(datagetEbookDetails['price'] != null && datagetEbookDetails['price'] !== '') ?
                                                                <Form.Label type="text" >{getCurrencyDisplay(convertToNumberWith2Decimal(datagetEbookDetails['price']))}</Form.Label>
                                                                :
                                                                <Form.Label type="text" > - </Form.Label>
                                                            }
                                                        </Col>
                                                    </Form.Group>
                                                </div>
                                            )}

                                            <br></br>
                                            <span className='inner-title-text'>E-Book File</span>
                                            <hr className='hr-title' />

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">
                                                    Upload E-Book File
                                                </Form.Label>
                                                <Col sm="6">
                                                {selectedPdf ? (
                                                        <div>
                                                        <Form.Label column sm="4">
                                                            There is a file uploaded
                                                        </Form.Label>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                        <Form.Label column sm="4">
                                                            There is no file uploaded
                                                        </Form.Label>
                                                        </div>
                                                    )}

                                                </Col>
                                            </Form.Group>

                                            <br></br>
                                            <span className='inner-title-text'>Status</span>
                                            <hr className='hr-title' />
                                            <Form.Group as={Row} className="mb-12" controlId="">
                                                <Form.Label column sm="4">
                                                    <span className='my-rowlabel'>  Status</span>
                                                </Form.Label>
                                                <Col >
                                                    {publishOption === 'as_review_pass' && (
                                                        <Form.Label >As soon as review pass</Form.Label>
                                                    )}

                                                    {publishOption === 'schedule' && (
                                                        <Form.Label >Scheduled</Form.Label>
                                                    )}

                                                </Col>
                                            </Form.Group>

                                            {publishOption === 'schedule' && (
                                                <div>
                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                        <Form.Label column sm="4">Public Date</Form.Label>
                                                        <Col sm="6">
                                                            <Form.Label type="text" >{localeString(datagetEbookDetails['publish_date'])} </Form.Label>
                                                        </Col>
                                                    </Form.Group>

                                                </div>
                                            )}

                                            <Form.Group as={Row} className="mb-3" controlId="">
                                                <Form.Label column sm="4">Note to Reviewers</Form.Label>
                                                <Col sm="6">
                                                    {(datagetEbookDetails['notes'] != null && datagetEbookDetails['notes'] !== '') ?
                                                        <Form.Label type="text" >{datagetEbookDetails['notes']} </Form.Label>
                                                        :
                                                        <Form.Label type="text" > - </Form.Label>
                                                    }
                                                </Col>
                                            </Form.Group>

                                            <br></br>

                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </main>
                </Col>
            </Row>
        </div >
    );
};

export default ViewEbooksAdmin;
