import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import '../../css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import Head from '../../components/navbar/Head.js';
import NavBar from '../../components/navbar/NavBar.js';
import Card from 'react-bootstrap/Card';
import { localeString ,getCustomerServiceAccount, getCustomerServiceReceiptNo } from '../../api'
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';


const CustomerSupport = () => {



    const [dataCustomerSuooirt, setDataCustomerSuooirt] = useState([]);
    const refAccount = useRef();
    const refReceiptNo = useRef();



    const searchAccount = () => {
        const dataAccount = refAccount.current.value;
        if (dataAccount) {
            getCustomerServiceAccount(dataAccount).then(response => {
                console.log(response.data);
                setDataCustomerSuooirt(response.data);
            });
        } else {
            setDataCustomerSuooirt([]);
        }
    }


    const searchReceiptNo = () => {
        const dataReceiptNo = refReceiptNo.current.value;
        if (dataReceiptNo) {
            getCustomerServiceReceiptNo(dataReceiptNo).then(response => {
                console.log(response.data);
                setDataCustomerSuooirt(response.data);
            });
        } else {
            setDataCustomerSuooirt([]);
        }
    }





    return (
        <div >
            <Head />
            <Row className='row-navbar'>
                <NavBar />
                <Col className='row-context' >
                    <main>

                        <div className='box-context'>
                            <div className='div-row-col'>
                                <Row sm={12}>

                                    <span className='title-text'>Customer Support</span>
                                    <p className='context-text'>Customer support description</p>

                                </Row>

                            </div>


                            <div className='box-table'>
                                <Card className='card-box'>
                                    <Card.Body>

                                        <Col>
                                            <main>
                                                <div className="card-1">
                                                    <Form noValidate >

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="3">
                                                                Account
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <Form.Control required type="text" maxLength={45} ref={refAccount} />
                                                            </Col>
                                                            <Button className='button-search button-main button-white' variant="primary"
                                                                onClick={() => { searchAccount(); }} >Check</Button>
                                                        </Form.Group>

                                                        <Form.Group as={Row} className="mb-3" controlId="">
                                                            <Form.Label column sm="3">
                                                                Receipt No.
                                                            </Form.Label>
                                                            <Col sm="5">
                                                                <Form.Control required type="text" maxLength={45} ref={refReceiptNo} />
                                                            </Col>
                                                            <Button className='button-search button-main button-white' variant="primary"
                                                                onClick={() => { searchReceiptNo(); }}
                                                            >Check</Button>
                                                        </Form.Group>
                                                    </Form>


                                                </div>
                                            </main>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </div>


                            <br></br>
                            <div>
                                {dataCustomerSuooirt?.user != null ?
                                    <div>
                                        <div className='box-table'>
                                            <Card className='card-box'>
                                                <Card.Body>

                                                    <Col>
                                                        <main>
                                                            <div className="card-1">
                                                                <Form noValidate >

                                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                                        <Form.Label column sm="3">
                                                                            Account
                                                                        </Form.Label>
                                                                        <Col sm="5">
                                                                            {dataCustomerSuooirt?.user?.account || '-'}
                                                                        </Col>
                                                                    </Form.Group>

                                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                                        <Form.Label column sm="3">
                                                                            Type Register
                                                                        </Form.Label>
                                                                        <Col sm="5">
                                                                            {dataCustomerSuooirt?.user?.tyoeRegister || '-'}
                                                                        </Col>
                                                                    </Form.Group>

                                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                                        <Form.Label column sm="3">
                                                                            Date Register
                                                                        </Form.Label>
                                                                        <Col sm="5">
                                                                            {localeString(dataCustomerSuooirt?.user?.dateRegister) || '-'}
                                                                        </Col>
                                                                    </Form.Group>



                                                                </Form>
                                                            </div>
                                                        </main>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </div>

                                        <br></br>


                                        <div className='box-table'>
                                            <Card className='card-box'>
                                                <Card.Body>

                                                    <Col>
                                                        <main>
                                                            <div className="card-1">
                                                                <Form noValidate >

                                                                    <Form.Group as={Row} className="mb-3" controlId="">
                                                                        <Form.Label column sm="3">
                                                                            Last Login
                                                                        </Form.Label>
                                                                        <Col sm="5">
                                                                      
                                                                            {localeString(dataCustomerSuooirt?.user?.datetime) || '-' } - { localeString(dataCustomerSuooirt?.user?.expire)}
                                                                        </Col>

                                                                    </Form.Group>


                                                                </Form>
                                                            </div>
                                                        </main>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </div>



                                        <br></br>


                                        <div className='box-table'>
                                            <Card className='card-box'>
                                                <Card.Body>

                                                    <Col>
                                                        <main>
                                                            <div className="card-1">
                                                                <Table hover >
                                                                    <thead className='thead-text'>
                                                                        <tr>
                                                                            <th><p>No</p></th>
                                                                            <th><p>Book Title</p></th>
                                                                            <th><p>Payment date</p></th>
                                                                            <th><p>Receipt Number</p></th>
                                                                            <th><p>Platform Payment</p></th>
                                                                            <th><p>Invoice Status</p></th>
                                                                            <th><p>Currency </p></th>
                                                                            <th><p>Downloaded</p></th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {Array.isArray(dataCustomerSuooirt['ebook']) ? (
                                                                            dataCustomerSuooirt['ebook'].map((option, index) => (


                                                                                <tr key={option["period_id"]}>

                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {index + 1}
                                                                                        </p>
                                                                                    </td>



                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {option['book_title']}
                                                                                        </p>
                                                                                        <p className='td-text'>
                                                                                          
                                                                                            <span className='td-textChangeStatus'>
                                                                                            <font style={{ float: 'left' }}>ISBN : {option['isbn_number']}</font>
                                                                                            </span>
                                                                                        </p>
                                                                                    </td>

                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {localeString(option['purchase_datetime'])}
                                                                                        </p>
                                                                                    </td>

                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {option['transaction_id'] || '-'}
                                                                                        </p>
                                                                                    </td>

                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {option['platform'] || '-'}
                                                                                        </p>
                                                                                    </td>



                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {option['status'] || '-'}
                                                                                        </p>
                                                                                    </td>

                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {option['currency_code'] || '-'}
                                                                                        </p>
                                                                                    </td>

                                                                                    <td style={{ verticalAlign: 'middle' }}>
                                                                                        <p className='td-text'>
                                                                                            {option['downloaded'] || '0'}
                                                                                        </p>
                                                                                    </td>



                                                                                </tr>
                                                                            ))
                                                                        ) : (
                                                                            <Form.Label type="text"></Form.Label>
                                                                        )}

                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </main>
                                                    </Col>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    </div>


                                    :
                                    <div>
                                        <center>
                                            No information found
                                        </center>
                                    </div>


                                }
                            </div>




                        </div>
                    </main>
                </Col>


            </Row>
        </div >
    );

}


export default CustomerSupport;